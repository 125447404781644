<div class="section-full p-t80">
    <div class="container">
             
        <div class="section-content"> 
        
            <div class="owl-carousel project-detail-slider owl-btn-vertical-center mfp-gallery">
            
                <div class="item" *ngFor="let object of data">
                    <div class="project-img-effect-1">
                        <img src="{{object}}" alt="" />
                    </div>
                </div>
                
            </div>

        </div>   
                                             
    </div>
</div>