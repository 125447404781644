<footer class="site-footer footer-large footer-dark text-white footer-style1" >


    <!-- FOOTER BLOCKES START -->
    <div class="footer-top bg-no-repeat bg-bottom-right" style="background-image:url(assets/images/background/footer-bg.png)">
        <div class="container">
            <div class="row">

                <div class="col-lg-5 col-md-12 col-sm-12">
                    <div class="footer-h-left">
                        <div class="widget widget_about">
                            <div class="logo-footer clearfix">
                                <a routerLink="/index"><img id="footerLogo1" src="assets/images/logo.png" alt="" ></a>
                            </div>
                            <p>
                              Premios Primavera es una entrega de premios para celebridades de
                              habla hispana en las areas de cine, musica, deportes, moda y cultura pop, presentada por producciones Colome
                            </p>
                         </div>
                        <div class="widget recent-posts-entry">
                           <ul class="widget_address">
                                <li><i class="fa fa-map-marker"></i>New York, NY</li>
                                <li><i class="fa fa-envelope"></i>produccionescolome@hotmail.com</li>
                                <li> <i class="fa fa-phone"></i>+1 347 636-2126 </li>
                            </ul>
                        </div>
                        <ul class="social-icons  wt-social-links footer-social-icon">
                            <li><a href="https://www.instagram.com/premiosprimavera/" class="fa fa-instagram"></a></li>
                            <li><a href="https://www.youtube.com/@ColomeProducciones" class="fa fa-youtube"></a></li>
                            <li><a href="https://www.facebook.com/produccionescolome" class="fa fa-facebook"></a></li>
                            <!-- <li><a href="https://twitter.com/" class="fa fa-twitter"></a></li>
                            <li><a href="https://www.linkedin.com/" class="fa fa-linkedin"></a></li> -->
                        </ul>
                    </div>

                </div>

                <div class="col-lg-7 col-md-12 col-sm-12">
                    <div class="row footer-h-right">
                        <div class="col-lg-5 col-md-4">
                            <div class="widget widget_services">
                                <h3 class="widget-title">Nuestros Projectos</h3>
                                <ul>
                                  <li><a href="https://alritmodelanoche.com/">Al Ritmo de la noche</a></li>
                                  <li><a href="http://premiosprimavera.com/">Premios Primavera</a></li>
                                  <li><a href="http://www.busqueda13.com/">Busqueda 13 Radio</a></li>
                                  <li><a href="http://radiocucaracha.com/"> Radio Cucaracha </a></li>
                                  <li><a routerLink="/other/biografia">Biografia </a></li>
                              </ul>
                            </div>
                        </div>

                        <div class="col-lg-7 col-md-8">
                            <div class="widget widget_services">
                                <h3 class="widget-title">Paginas Amigas</h3>
                                <ul>
                                  <li><a routerLink="/services/s-oilgas">Disponible</a></li>
                                  <li><a routerLink="/services/s-chemical">Disponible</a></li>
                                  <li><a routerLink="/services/s-mechanical">Disponible</a></li>
                                  <li><a routerLink="/services/s-civil">Disponible</a></li>
                                  <li><a routerLink="/services/s-automotive">Disponible</a></li>
                              </ul>
                            </div>
                        </div>

                    </div>

                    <!-- <div class="widget widget_newsletter">
                        <h3 class="widget-title">Newsletter</h3>
                            <p>Subscribe to our newsletter to receive latest news on our services.</p>
                            <div class="newsletter-input">
                              <div class="input-group">
                                <input id="email" type="text" class="form-control" name="email" placeholder="Enter your email">
                                <div class="input-group-append">
                                  <button type="submit" class="input-group-text nl-search-btn text-black site-bg-primary title-style-2">Subscribe</button>
                                </div>
                              </div>
                            </div>
                    </div> -->

                </div>

            </div>
        </div>
    </div>
    <!-- FOOTER COPYRIGHT -->

    <div class="footer-bottom">
      <div class="container">
        <div class="wt-footer-bot-left d-flex justify-content-between">
          <span class="copyrights-text">Developed by: <a target="_blank" href="http://www.g7soluciones.net"><span class="site-text-primary">G7 Soluciones</span></a></span>
          <ul class="copyrights-nav">
              <!-- <li><a routerLink="/about/about1">Terms  &amp; Condition</a></li> -->
              <li><a >Politica de Privacidad</a></li>
              <li><a routerLink="/other/contact1">Contacto</a></li>
          </ul>
        </div>
      </div>
    </div>


</footer>
