import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-home1',
  templateUrl: './page-home1.component.html',
  styleUrls: ['./page-home1.component.css']
})
export class PageHome1Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  about = {
    title: "Premios Primavera",
    subtitle: "Los premios de los Latinos desde New York",
    image: "assets/images/colarge/aboutHome.png",
    since: "2019",
    description: "Premios Primavera es una entrega de premios para celebridades de habla hispana en las areas de cine, musica, deportes, moda y cultura pop, presentada por producciones Colome.Los ganadores se determinan mediante votacion en linea.",
    sign: "assets/images/sign.png",
    authorized: "Dario Colome",
    post: "CEO & Founder",
    services: [
      {
        value: "Premiando el Talento"
      },
      {
        value: "Reconociendo la Trayectoria Artistica"
      },
      {
        value: "Reconoce el trabajo, realizado por destacadas personalidades "
      }
    ]
  }

  services = {
    title: "Premios Primavera",
    subtitle: "Nuestros Patrocinadores",
    description: "Agredecemos a nuestros patrocinadores por confiar en nosotros y brindarnos su apoyo.",
    services: [
      {
        title: "Super Intendencia de Seguros",
        description: "Progressively maintain extensive intermediaries via extensible is nich. Capitalize on low hanging machine and tools.",
        image: "/assets/images/client-logo/superintendencia.png",
        icon: "flaticon-industry",
        serial: "01",
        pagelink: "https://www.superseguros.gob.do/"
      },
      {
        title: "Anunciante",
        description: "Progressively maintain extensive intermediaries via extensible is nich. Capitalize on low hanging machine and tools.",
        icon: "flaticon-conveyor",
        image: "assets/images/colarge/aboutHome.png",
        serial: "02",
        pagelink: "s-mechanical"
      },
      {
        title: "Anunciante",
        description: "Progressively maintain extensive intermediaries via extensible is nich. Capitalize on low hanging machine and tools.",
        icon: "flaticon-robotic-arm",
        image: "assets/images/colarge/aboutHome.png",
        serial: "03",
        pagelink: "s-automotive"
      }

    ]
  }

  services2 = {
    title: "Premios Primavera",
    subtitle: "Nuestros Nominados",
    description: "Agredecemos a nuestros patrocinadores por confiar en nosotros y brindarnos su apoyo.",
    services: [
      {
        title: "Nominado",
        description: "Progressively maintain extensive intermediaries via extensible is nich. Capitalize on low hanging machine and tools.",
        image: "assets/images/colarge/aboutHome.png",
        icon: "flaticon-industry",
        serial: "01",
        pagelink: "s-oilgas"
      },
      {
        title: "Nominado",
        description: "Progressively maintain extensive intermediaries via extensible is nich. Capitalize on low hanging machine and tools.",
        icon: "flaticon-conveyor",
        image: "assets/images/colarge/aboutHome.png",
        serial: "02",
        pagelink: "s-mechanical"
      },
      {
        title: "Nominado",
        description: "Progressively maintain extensive intermediaries via extensible is nich. Capitalize on low hanging machine and tools.",
        icon: "flaticon-robotic-arm",
        image: "assets/images/colarge/aboutHome.png",
        serial: "03",
        pagelink: "s-automotive"
      },
      {
        title: "Nominado",
        description: "Progressively maintain extensive intermediaries via extensible is nich. Capitalize on low hanging machine and tools.",
        icon: "flaticon-oil",
        image: "assets/images/colarge/aboutHome.png",
        serial: "04",
        pagelink: "s-chemical"
      },
      {
        title: "Nominado",
        description: "Progressively maintain extensive intermediaries via extensible is nich. Capitalize on low hanging machine and tools.",
        icon: "flaticon-scythe",
        image: "assets/images/colarge/aboutHome.png",
        serial: "05",
        pagelink: "s-agricultural"
      },
      {
        title: "Nominado",
        description: "Progressively maintain extensive intermediaries via extensible is nich. Capitalize on low hanging machine and tools.",
        icon: "flaticon-helmet",
        image: "assets/images/colarge/aboutHome.png",
        serial: "06",
        pagelink: "s-civil"
      }
    ]
  }


  whatwedo = {
    title: "What We do",
    subtitle: "Providing Full Range of High Services Solution Worldwide",
    description: "Progressively maintain extensive infomediaries via extensible niches. Capitalize on low hanging fruit to identify a ballpark value added is activity to beta test. Override the igital divide",
    image: "assets/images/what-we/pic1.jpg",
    experience: {
      title: "Team Working Dedicatedly",
      description: "We have 26+ years of experience with providing wide area of specialty services works listed below"
    },
    work: [
      {
        icon: "flaticon-robotic-arm",
        title: "A Full Services",
        description: "We are Providing different services in this sector to wide area of world"
      },
      {
        icon: "flaticon-repair",
        title: "All Maintenance",
        description: "We are Prous to Protect your organization with our award-winning products"
      }
    ]
  }

  video = {
    title: "All Solutions",
    subtitle: "Get A Solution For All Industries",
    bgImage: "assets/images/background/bg-2.jpg",
    bgOpacity: "09",
    bgColor: "bg-white",
    videolink: "https://www.youtube.com/watch?v=fgExvIUYg5w",
    solutions: [
      {
        count: "35",
        title: "Projects Completed"
      },
      {
        count: "1435",
        title: "Work Employed"
      },
      {
        count: "750",
        title: "Work facilities"
      }
    ],
    description: {
      title: "The Best Solution For all industries",
      subtitle: "Seving an impressive list of long-term clients with experience and expertise in industries.",
      quote: "Many of Our SELF registered employees are requested an main preferred temporary staff when all service",
      quoteby: "Farnandoz Biki, CEO"
    }
  }

  projects = {
    title: "Explore Recent Work",
    subtitle: "We've Done Lot's Projects Let's Check Some Here",
    description: "You will find yourself working in a partnership that result in an incredible Experience, and an end products that is the best",
    image: "assets/images/crane.png",
    projects: [
      {
      title: "Manufacturing & Maintenance",
      subtitle: "Mechanical Engineering",
      description: "You will find yourself working that result partnership in an incredible Experience, and an end products that is the best",
      image: "assets/images/project/pic1.jpg"
      },
      {
        title: "Manufacturing & Maintenance",
        subtitle: "Mechanical Engineering",
        description: "You will find yourself working that result partnership in an incredible Experience, and an end products that is the best",
        image: "assets/images/project/pic2.jpg"
      },
      {
        title: "Manufacturing & Maintenance",
        subtitle: "Mechanical Engineering",
        description: "You will find yourself working that result partnership in an incredible Experience, and an end products that is the best",
        image: "assets/images/project/pic3.jpg"
      },
      {
        title: "Manufacturing & Maintenance",
        subtitle: "Mechanical Engineering",
        description: "You will find yourself working that result partnership in an incredible Experience, and an end products that is the best",
        image: "assets/images/project/pic4.jpg"
      }
    ]
  }

  team = {
    title: "Nuestros Presentadores",
    subtitle: "Presentadores de la gala de premiacion y Alfombra Verde ",
    boxWidth: "col-lg-4",
    members: [
      {
        image: "assets/images/nominados/PRESENTADORES_PREMIACIÓN.jpg",
        name: "Manny Rodriguez &",
        name2: "Mariely de la Cruz ",
        post: "Presentadores Gala Premiacion"
      },
      {
        image: "assets/images/nominados/ALFOMBRA_VERDE.jpg",
        name: "Jose Luis De Leon &",
        name2: "Tairis Cabrera ",
        post: "Presentadores Alfombra Verde"
      }
     ]
  }

  testimonials = {
    title: "What our client say",
    subtitle: "Happy WIth Customers & Clients",
    description: "I feel very happy and be proud to connect with this industry. i presume this is a very productive and professional industry.i wish very good luck & success for this industry",
    quotesTitle: "Client says",
    quotes: [
      {
        image: "assets/images/testimonials/pic1.jpg",
        quote: "This is not just another nail salon! These ladies are super talented! My nails have never looked/felt more amazing!! the environment here is so happy and cheery!",
        name: "Malcolm Franzcrip",
        post: "Contractor"
      },
      {
        image: "assets/images/testimonials/pic2.jpg",
        quote: "This is not just another nail salon! These ladies are super talented! My nails have never looked/felt more amazing!! the environment here is so happy and cheery!",
        name: "Malcolm Franzcrip",
        post: "Contractor"
      },
      {
        image: "assets/images/testimonials/pic3.jpg",
        quote: "This is not just another nail salon! These ladies are super talented! My nails have never looked/felt more amazing!! the environment here is so happy and cheery!",
        name: "Malcolm Franzcrip",
        post: "Contractor"
      }
    ]
  }

  blogs = {
    title: "Latest Articles Updated Daily",
    subtitle: "We Are Here To Learn You More From Blog",
    blogs: [
      {
        image: "assets/images/blog/default/thum1.jpg",
        field: "Materials",
        date: "July 28, 2021",
        comments: "24",
        title: "Technology is important for your business, you use technology makes"
      },
      {
        image: "assets/images/blog/default/thum2.jpg",
        field: "Technology",
        date: "July 28, 2021",
        comments: "24",
        title: "Present you with examples of 3 posts from authority websites click"
      },
      {
        image: "assets/images/blog/default/thum3.jpg",
        field: "Manufacturing",
        date: "July 28, 2021",
        comments: "24",
        title: "Explain to you why they work and what makes people click them"
      }
    ]
  }

  clients = {
    bgcolor: "bg-gray",
    clients: [
      "assets/images/client-logo/w1.png",
      "assets/images/client-logo/w2.png",
      "assets/images/client-logo/w3.png",
      "assets/images/client-logo/w4.png",
      "assets/images/client-logo/w5.png",
      "assets/images/client-logo/w6.png",
      "assets/images/client-logo/w1.png",
      "assets/images/client-logo/w2.png",
      "assets/images/client-logo/w3.png",
      "assets/images/client-logo/w4.png",
      "assets/images/client-logo/w5.png",
      "assets/images/client-logo/w6.png",
      "assets/images/client-logo/w1.png",
      "assets/images/client-logo/w2.png",
      "assets/images/client-logo/w3.png",
      "assets/images/client-logo/w4.png",
      "assets/images/client-logo/w5.png",
      "assets/images/client-logo/w6.png"
    ]
  }
}
