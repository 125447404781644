    <!-- HEADER START -->
    <app-section-header1></app-section-header1>
    <!-- HEADER END -->
<router-outlet></router-outlet>




<!-- BUTTON TOP START -->
<button class="scroltop"><span class="fa fa-angle-up  relative" id="btn-vibrate"></span></button>

<!-- Get In Touch -->
<app-section-get-in-touch></app-section-get-in-touch>

    <!-- FOOTER START -->
    <app-section-footer1></app-section-footer1>
    <!-- FOOTER END -->
