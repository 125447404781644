<div class="section-full self-intro-section-outer overlay-wraper {{data.bgCover}}" style="background-image:url({{data.bgImage}})">
    <div class="overlay-main site-bg-secondry {{data.opacity}}"></div>
    <div class="self-intro-top {{data.bgClass}} p-t80 p-b50">
        <div class="container">
            <div class="row justify-content-end">

                <div class="col-lg-6 col-md-12">
                    <!-- TITLE START-->
                    <div class="left wt-small-separator-outer">
                        <div class="wt-small-separator site-text-primary">
                            <div  class="sep-leaf-left"></div>
                            <div>Producciones colome</div>
                            <div  class="sep-leaf-right"></div>
                        </div>
                        <h2>Con m&aacute;s  de 30+ a&ntilde;os en la Industria Artistica</h2>
                        <p>un reconocido empresario, productor y promotor artístico radicado en la última década en la ciudad de Nueva York, donde ha cosechado gran éxito.

                        </p>
                    </div>
                    <!-- TITLE END-->
                </div>

            </div>
        </div>
    </div>

    <div class="self-intro-bottom p-t80 p-b80">
        <div class="container">
            <div class="row justify-content-end">

                <div class="col-lg-6 col-md-6">
                    <div class="self-info-detail">

                        <div class="wt-icon-box-wraper p-b10 left">
                            <div class="icon-md m-b20">
                                <span class="icon-cell site-text-primary"><i class="flaticon-call"></i></span>
                            </div>
                            <div class="icon-content text-white">
                                <h3 class="wt-tilte">¿Tiene una pregunta? Llámanos ahora</h3>
                                <p>+1 (347) 636-2126</p>
                            </div>
                        </div>

                        <div class="wt-icon-box-wraper p-b10  left">
                            <div class="icon-md m-b20">
                                <span class="icon-cell site-text-primary"><i class="flaticon-mail"></i></span>
                            </div>
                            <div class="icon-content text-white">
                                <h3 class="wt-tilte">¿Necesita ayuda? Envíenos un correo electrónico</h3>
                                <h4>produccionescolome@hotmail.com</h4>
                            </div>
                        </div>

                        <div class="wt-icon-box-wraper  left">
                            <div class="icon-md m-b20">
                                <span class="icon-cell site-text-primary"><i class="flaticon-history"></i></span>
                            </div>
                            <div class="icon-content text-white">
                                <h3 class="wt-tilte">Conoce mas de mi</h3>
                                <h4><a routerLink="/other/biografia" class="wt-tilte">Biografia </a></h4>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="container">
        <div class="{{data.imageClass}}">
            <div class="wt-media"><img src="{{data.image}}" alt=""></div>
        </div>
    </div>

</div>
