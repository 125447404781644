<div class="section-full p-t80 p-b40 bg-no-repeat bg-bottom-right bg-cover" style="background-image:url(assets/images/background/bg-7.jpg)">
    <div class="container">
        <div class="section-content">
        
        <!-- TITLE START-->
            <div class="section-head center wt-small-separator-outer text-center">
                <div class="wt-small-separator site-text-primary">
                    <div class="sep-leaf-left"></div>
                    <div>{{data.title}}</div>
                    <div class="sep-leaf-right"></div>
                </div>
                <h2>{{data.subtitle}}</h2>
            </div>
        <!-- TITLE END-->
         
            <div class="row d-flex justify-content-center">
            
                    <div class="col-lg-4 col-md-6 col-sm-12 m-b50" *ngFor="let object of data.services">
                        <div class="service-border-box">
                            <div class="wt-box service-box-1 bg-white">
                                
                                <div class="service-box-title title-style-2 site-text-secondry">
                                    <span  class="s-title-one">{{object.title1}} </span>
                                    <span  class="s-title-two">{{object.title2}}</span>     
                                </div>
                                <div class="service-box-content">
                                    <p>{{object.description}}</p>
                                    <a routerLink="/{{object.pageLink}}" class="site-button-link">Read More</a>
                                </div>
                                
                                <div class="wt-icon-box-wraper">
                                    <div class="wt-icon-box-md site-bg-primary">
                                        <span class="icon-cell text-white"><i class="{{object.icon}}"></i></span>
                                    </div>
                                    <div class="wt-icon-number"><span>{{object.count}}</span></div>
                                </div>                                            
                                                                            
                            </div>
                        </div>                     
                    </div>
                                                                    
            </div>
        </div>
    </div>
</div> 