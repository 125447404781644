<!-- Modal -->
<div class="fondo-modal-imagen animated fadeIn fast"
    [class.oculto]="ocultarModal">



<div class="modal fade show  " id="exampleModalLive" tabindex="-1" aria-labelledby="exampleModalLiveLabel" style="display: block; padding-right: 15px;" aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered  modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title w-100 text-center " id="exampleModalLiveLabel">Transmisi&oacute;n en VIVO, Gracias a Colome TV</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cerrarModal()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">

                          <!-- Dominit RC�HD� video player version YR18- Copyright Dominican Internet Group� -->
                          <iframe
                          width="780px" height="450px" frameBorder="0" allowfullscreen allow-same-origin allow-scripts
                          src="https://dominicanplayers.com/video-player/1707/0">
                        </iframe>
                   <!-- Dominit RC�HD� video player version YR18- Copyright Dominican Internet Group� -->

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
        (click)="cerrarModal()">Cerrar</button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div>
  </div>
</div>
</div>
