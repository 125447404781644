<div class="page-wraper">

    <!-- HEADER START -->
    <!-- <app-section-header1></app-section-header1> -->
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">

        <!-- SLIDER START -->
        <app-section-slider10></app-section-slider10>
        <!-- SLIDER END -->

        <!-- CALL US SECTION START -->
        <app-section-call-us1 [data]="callUs"></app-section-call-us1>
        <!-- CALL US SECTION END -->

        <!-- ABOUT ONE SECTION START -->
        <app-section-about6 [data]="about"></app-section-about6>
        <!-- ABOUT ONE SECTION END -->

        <!-- OUR PROJECTS SECTION START -->
        <app-section-projects8 [data]="projects"></app-section-projects8>
        <!-- OUR PROJECTS SECTION END -->

        <!-- SERVICE SECTION START -->
        <app-section-services8 [data]="services"></app-section-services8>
        <!-- SERVICES SECTION END -->

        <!-- QUALITY SECTION START -->
        <app-section-quality2 [data]="quality"></app-section-quality2>
        <!-- QUALITY SECTION END -->

        <!-- OUR TEAM START -->
        <app-section-team4 [data]="team"></app-section-team4>
        <!-- OUR TEAM SECTION END -->

        <!-- OUR BLOG START -->
        <app-section-blogs8 [data]="blogs"></app-section-blogs8>
        <!-- OUR BLOG END -->

        <!-- TESTIMONIAL SECTION START -->
        <app-section-testimonials5 [data]="testimonials"></app-section-testimonials5>
        <!-- TESTIMONIAL SECTION END -->

    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <!-- <app-section-footer3></app-section-footer3> -->
    <!-- FOOTER END -->

 </div>

<app-loader></app-loader>
