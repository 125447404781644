<div class="page-wraper"> 
    
    <!-- HEADER START -->
    <app-section-header2></app-section-header2>
    <!-- HEADER END -->
    
    <!-- CONTENT START -->
    <div class="page-content">
    
        <!-- INNER PAGE BANNER -->
        <app-section-inner-banner [data]="banner"></app-section-inner-banner>
        <!-- INNER PAGE BANNER END -->
        
        <!-- FAQ SECTION START -->
        <div class="section-full p-t80 p-b50 bg-white">
            <div class="container">
            
                <!-- TITLE START-->
                <div class="section-head center wt-small-separator-outer text-center">
                    <div class="wt-small-separator site-text-primary">
                        <div  class="sep-leaf-left"></div>
                        <div>Frequently Asked Questions?</div>
                        <div  class="sep-leaf-right"></div>
                    </div>
                    <h2>Find informative answers to all your questions about Industrial products below.</h2>
                </div>
                <!-- TITLE END-->   
                             
                <div class="section-content">
                           
                    <div class="row d-flex justify-content-center">
                    
                        <div class="col-lg-8 col-md-6 col-sm-12">
                        
                             <!-- Accordian -->
                            <div class="wt-accordion acc-bg-gray faq-accorfion" id="accordion5">
                            
                                <div class="panel wt-panel" *ngFor="let object of faqs; let i = index;">
                                    <div class="acod-head {{ i == 0 ? 'acc-actives' : '' }}">
                                         <h3 class="acod-title">
                                            <a data-toggle="collapse" href="#collapse{{i}}5" class="{{ i == 0 ? '' : 'collapsed' }}" data-parent="#accordion5" > 
                                                {{object.q}}
                                                <span class="indicator"><i class="fa fa-plus"></i></span>
                                            </a>
                                         </h3>
                                    </div>
                                    <div id="collapse{{i}}5" class="acod-body collapse {{ i == 0 ? 'show' : '' }}">
                                        <div class="acod-content p-a20 bg-gray" [innerHTML]="object.a">
                                        </div>
                                    </div>
                                </div>  
                                                                    
                            </div>
                        </div>
                        
                        <div class="col-lg-4 col-md-6 col-sm-12">
                        
                            <div class="faq-sidebar">
                                <div class="service-side-btn m-b30 site-bg-secondry p-a20 text-white">
                                   <h2 class="wt-title">Brochures</h2>
                                    <p>View our 2021  financial prospectus brochure for an easy to read guide on all of the services offer.</p>
                                    
                                    
                                    <div class="wt-icon-box-wraper left ">
                                        <a routerLink="/about/about1" class="btn-block  p-a15 m-tb10">
                                            <span class="text-black m-r10"><i class="fa fa-file-pdf-o"></i></span>
                                            <strong class="text-uppercase text-black">Download .PDF</strong>
                                        </a>
                                    </div>
                                    <div class="wt-icon-box-wraper left">
                                        <a routerLink="/about/about1" class="btn-block  p-a15 m-tb10">
                                            <span class="text-black m-r10"><i class="fa fa-file-word-o"></i></span>
                                            <strong class="text-uppercase text-black"> Download .DOC</strong>
                                        </a>
                                    </div>
                                    <div class="wt-icon-box-wraper left">
                                        <a routerLink="/about/about1" class="btn-block p-a15 m-t10">
                                            <span class="text-black m-r10"><i class="fa fa-file-powerpoint-o"></i></span>
                                            <strong class="text-uppercase text-black">Download .PPT</strong>
                                        </a>
                                    </div>                                 
                                </div> 
                                
                            <div class="wt-box p-a30 need-help site-bg-primary text-white m-b30">
                                <h2 class="m-t0">Need any help!</h2>
                                <p>Find answers to frequently asked questions about Bizipress, contacts and general customer account information </p>
                                <a routerLink="/other/contact1" class="site-button-secondry site-btn-effect">Contact Us</a>
                            </div>                                                                   
                            </div>
                                                            
                        </div>                            
                        
                                                    
                    </div>
                    
                </div>
            </div>
        </div>   
        <!-- FAQ SECTION END -->
        
    </div>
    <!-- CONTENT END -->
    
    <!-- FOOTER START -->
    <app-section-footer1></app-section-footer1>
    <!-- FOOTER END -->
    
</div>

<app-loader></app-loader>
