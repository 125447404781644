import { Component } from '@angular/core';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.css']
})
export class VideoModalComponent {

  private _ocultarModal: boolean = false;

  get ocultarModal() {
    return this._ocultarModal;
  }

  public imagenSubir: File;
  public imgTemp: any = null;

  cerrarModal() {
    this._ocultarModal = true;
  }

}
  