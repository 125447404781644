<div id="welcome_wrapper" class="rev_slider_wrapper fullscreen-container" data-alias="goodnews-header" data-source="gallery" style="background:#eeeeee;padding:0px;">
    <!-- START REVOLUTION SLIDER 5.4.3.1 fullscreen mode -->
        <div id="welcome" class="rev_slider fullscreenbanner" style="display:none;" data-version="5.4.3.1">
    <ul>	

        <!-- SLIDE  -->
        <li data-index="rs-900" data-transition="fade" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off"  data-easein="default" data-easeout="default" data-masterspeed="default"  data-thumb="assets/images/home-11/slider2/slide1.jpg"  data-rotate="0"  data-saveperformance="off"  data-title="Make your Office a Place you Want to Work at" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
            <!-- MAIN IMAGE -->
            <img src="assets/images/home-11/slider2/slide1.jpg"  alt=""  data-lazyload="assets/images/home-11/slider2/slide1.jpg" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="4" class="rev-slidebg" data-no-retina>
            <!-- LAYERS -->

    
            <!-- LAYER NR. 1 [ for overlay ] -->
            <div class="tp-caption tp-shape tp-shapewrapper " 
            id="slide-900-layer-1" 
            data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" 
            data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" 
            data-width="full"
            data-height="full"
            data-whitespace="nowrap"
            data-type="shape" 
            data-basealign="slide" 
            data-responsive_offset="off" 
            data-responsive="off"
            
            data-frames='[
            {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
            {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
            ]'
            data-textAlign="['left','left','left','left']"
            data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]"
            data-paddingbottom="[0,0,0,0]"
            data-paddingleft="[0,0,0,0]"
            
            style="z-index: 1;background-color:rgba(0, 0, 0, 0);border-color:rgba(0, 0, 0, 0);border-width:0px;"> 
            </div>

                         

            <!-- LAYER NR. 2 [ Small Title ] -->
            <div class="tp-caption   tp-resizeme" 
            id="slide-900-layer-2" 
            data-x="['left','left','left','left']" data-hoffset="['100','100','30','30']" 
            data-y="['middle','middle','middle','middle']" data-voffset="['-150','-150','-150','-120']" 
            data-fontsize="['28','28','28','28']"
            data-lineheight="['56','56','56','50']"
            data-width="['700','700','700','400']"
            data-height="['none','none','none','none']"
            data-whitespace="['normal','normal','normal','normal']"

            data-type="text" 
            data-responsive_offset="on" 
            data-frames='[
            {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},
            {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
            ]'
            data-textAlign="['left','left','left','left']"
            data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]"
            data-paddingbottom="[0,0,0,0]"
            data-paddingleft="[0,0,0,0]"

            style="z-index: 13; 
            white-space: normal;
            font-weight: 600;
            color:#111;
            border-width:0px; font-family: 'Teko', sans-serif">Quality that is guaranted</div>
            

                 
            <!-- LAYER NR. 3 [ Large title ] -->
            <div class="tp-caption   tp-resizeme" 
            id="slide-900-layer-3" 
            data-x="['left','left','left','left']" data-hoffset="['100','100','30','30']" 
            data-y="['middle','middle','middle','middle']" data-voffset="['-50','-50','-50','-50']"  
            data-fontsize="['72','72','72','38']"
            data-lineheight="['66','66','66','38']"
            data-width="['700','700','700','400']"
            data-height="['none','none','none','none']"
            data-whitespace="['normal','normal','normal','normal']"

            data-type="text" 
            data-responsive_offset="on" 
            data-frames='[{"from":"x:[105%];z:0;rX:45deg;rY:0deg;rZ:90deg;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;","speed":2000,"to":"o:1;","delay":1000,"split":"chars","splitdelay":0.05,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;","ease":"Power2.easeInOut"}]'
            data-textAlign="['left','left','left','left']"
            data-paddingtop="[5,5,5,5]"
            data-paddingright="[0,0,0,0]"
            data-paddingbottom="[0,0,0,0]"
            data-paddingleft="[0,0,0,0]"

            style="z-index: 13; 
            white-space: normal; 
            font-weight: 600;
            color:#fff;
            border-width:0px;">
            <div style="font-family:'Teko', sans-serif;">
                <span class="site-text-primary">Expert Heating, Cooling, and Air Conditioning</span>
            </div>
            
            </div>

            <!-- LAYER NR. 4 [ for paragraph] -->
            <div class="tp-caption  tp-resizeme" 
            id="slide-900-layer-4" 
            data-x="['left','left','left','left']" data-hoffset="['100','100','30','30']" 
            data-y="['middle','middle','middle','middle']" data-voffset="['60','60','70','50']"  
            data-fontsize="['20','20','20','16']"
            data-lineheight="['30','30','30','22']"
            data-width="['600','600','90%','90%']"
            data-height="['none','none','none','none']"
            data-whitespace="['normal','normal','normal','normal']"

            data-type="text" 
            data-responsive_offset="on"
            data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","speed":2000,"to":"o:1;","delay":3000,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
            data-textAlign="['left','left','left','left']"
            data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]"
            data-paddingbottom="[0,0,0,0]"
            data-paddingleft="[0,0,0,0]"

            style="z-index: 13; 
            font-weight: 500; 
            color:#111;
            border-width:0px;">
            <span style="font-family: 'Poppins', sans-serif;">
                We are running conditioning firm since five decades. as time passes we are getting more advance as technology.  
            </span>
            </div>



            <!-- LAYER NR. 5 [ for see all service botton ] -->
            <div class="tp-caption tp-resizeme" 	
            id="slide-900-layer-5"						
            data-x="['left','left','left','left']" data-hoffset="['100','100','30','30']" 
            data-y="['middle','middle','middle','middle']" data-voffset="['150','150','160','140']"  
            data-lineheight="['none','none','none','none']"
            data-width="['300','300','300','300']"
            data-height="['none','none','none','none']"
            data-whitespace="['normal','normal','normal','normal']"
            
            data-type="text" 
            data-responsive_offset="on"
            data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","speed":2000,"to":"o:1;","delay":3000,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
            data-textAlign="['left','left','left','left']"
            data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]"
            data-paddingbottom="[0,0,0,0]"
            data-paddingleft="[0,0,0,0]"
            
            style="z-index:13; text-transform:uppercase;">
            <a routerLink="/products/product" class="site-button-secondry site-btn-effect">Book Now</a>
            </div>

            <!-- Border Arround -->
            <div class="tp-caption tp-shape tp-shapewrapper " 
                 id="slide-900-layer-6" 
                 data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" 
                 data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" 
                            data-width="full"
                data-height="full"
                data-whitespace="nowrap"
                data-visibility="['on','on','off','off']"
     
                data-type="shape" 
                data-basealign="slide" 
                data-responsive_offset="off" 
                data-responsive="off"
                data-frames='[{"delay":50,"speed":100,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"frame":"999","to":"opacity:0;","ease":"Power3.easeIn"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
    
                style="z-index: 5;background-color:rgba(0, 0, 0, 0); border-color:#eff3f6 ;border-style:solid;border-width:50px 50px 50px 50px;"></div>
    
            <!-- Border Area -->
         
        </li>

        <!-- SLIDE  -->
        <li data-index="rs-901" data-transition="fade" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off"  data-easein="default" data-easeout="default" data-masterspeed="default"  data-thumb="assets/images/home-11/slider2/slide2.jpg"  data-rotate="0"  data-saveperformance="off"  data-title="Make your Office a Place you Want to Work at" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
            <!-- MAIN IMAGE -->
            <img src="assets/images/home-11/slider2/slide2.jpg"  alt=""  data-lazyload="assets/images/home-11/slider2/slide2.jpg" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="4" class="rev-slidebg" data-no-retina>
            <!-- LAYERS -->

    
            <!-- LAYER NR. 1 [ for overlay ] -->
            <div class="tp-caption tp-shape tp-shapewrapper " 
            id="slide-901-layer-1" 
            data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" 
            data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" 
            data-width="full"
            data-height="full"
            data-whitespace="nowrap"
            data-type="shape" 
            data-basealign="slide" 
            data-responsive_offset="off" 
            data-responsive="off"
            data-frames='[
            {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
            {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
            ]'
            data-textAlign="['left','left','left','left']"
            data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]"
            data-paddingbottom="[0,0,0,0]"
            data-paddingleft="[0,0,0,0]"
            
            style="z-index: 1;background-color:rgba(0, 0, 0, 0);border-color:rgba(0, 0, 0, 0);border-width:0px;"> 
            </div>

                         

            <!-- LAYER NR. 2 [ Small Title ] -->
            <div class="tp-caption   tp-resizeme" 
            id="slide-901-layer-2" 
            data-x="['left','left','left','left']" data-hoffset="['100','100','30','30']" 
            data-y="['middle','middle','middle','middle']" data-voffset="['-150','-150','-150','-120']" 
            data-fontsize="['28','28','28','28']"
            data-lineheight="['56','56','56','50']"
            data-width="['700','700','700','400']"
            data-height="['none','none','none','none']"
            data-whitespace="['normal','normal','normal','normal']"

            data-type="text" 
            data-responsive_offset="on" 
            data-frames='[
            {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},
            {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
            ]'
            data-textAlign="['left','left','left','left']"
            data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]"
            data-paddingbottom="[0,0,0,0]"
            data-paddingleft="[0,0,0,0]"

            style="z-index: 13; 
            white-space: normal;
            font-weight: 600;
            color:#111;
            border-width:0px; font-family: 'Teko', sans-serif">Choose the best company</div>
            

                 
            <!-- LAYER NR. 3 [ Large title ] -->
            <div class="tp-caption   tp-resizeme" 
            id="slide-901-layer-3" 
            data-x="['left','left','left','left']" data-hoffset="['100','100','30','30']" 
            data-y="['middle','middle','middle','middle']" data-voffset="['-50','-50','-50','-50']"  
            data-fontsize="['72','72','72','38']"
            data-lineheight="['66','66','66','38']"
            data-width="['700','700','700','400']"
            data-height="['none','none','none','none']"
            data-whitespace="['normal','normal','normal','normal']"

            data-type="text" 
            data-responsive_offset="on" 
            data-frames='[{"from":"x:[105%];z:0;rX:45deg;rY:0deg;rZ:90deg;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;","speed":2000,"to":"o:1;","delay":1000,"split":"chars","splitdelay":0.05,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;","ease":"Power2.easeInOut"}]'
            data-textAlign="['left','left','left','left']"
            data-paddingtop="[5,5,5,5]"
            data-paddingright="[0,0,0,0]"
            data-paddingbottom="[0,0,0,0]"
            data-paddingleft="[0,0,0,0]"

            style="z-index: 13; 
            white-space: normal; 
            font-weight: 600;
            color:#fff;
            border-width:0px;">
            <div style="font-family:'Teko', sans-serif;">
                <span class="site-text-primary">High-Quality Heating Installation & Repair</span>
            </div>
            
            </div>

            <!-- LAYER NR. 4 [ for paragraph] -->
            <div class="tp-caption  tp-resizeme" 
            id="slide-901-layer-4" 
            data-x="['left','left','left','left']" data-hoffset="['100','100','30','30']" 
            data-y="['middle','middle','middle','middle']" data-voffset="['60','60','70','50']"  
            data-fontsize="['20','20','20','16']"
            data-lineheight="['30','30','30','22']"
            data-width="['600','600','90%','90%']"
            data-height="['none','none','none','none']"
            data-whitespace="['normal','normal','normal','normal']"

            data-type="text" 
            data-responsive_offset="on"
            data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","speed":2000,"to":"o:1;","delay":3000,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
            data-textAlign="['left','left','left','left']"
            data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]"
            data-paddingbottom="[0,0,0,0]"
            data-paddingleft="[0,0,0,0]"

            style="z-index: 13; 
            font-weight: 500; 
            color:#111;
            border-width:0px;">
            <span style="font-family: 'Poppins', sans-serif;">
                We are running conditioning firm since five decades. as time passes we are getting more advance as technology.  
            </span>
            </div>



            <!-- LAYER NR. 5 [ for see all service botton ] -->
            <div class="tp-caption tp-resizeme" 	
            id="slide-901-layer-5"						
            data-x="['left','left','left','left']" data-hoffset="['100','100','30','30']" 
            data-y="['middle','middle','middle','middle']" data-voffset="['150','150','160','140']"   
            data-lineheight="['none','none','none','none']"
            data-width="['300','300','300','300']"
            data-height="['none','none','none','none']"
            data-whitespace="['normal','normal','normal','normal']"
            
            data-type="text" 
            data-responsive_offset="on"
            data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","speed":2000,"to":"o:1;","delay":3000,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
            data-textAlign="['left','left','left','left']"
            data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]"
            data-paddingbottom="[0,0,0,0]"
            data-paddingleft="[0,0,0,0]"
            
            style="z-index:13; text-transform:uppercase;">
            <a routerLink="/products/product" class="site-button-secondry site-btn-effect">Book Now</a>
            </div>

            <!-- Border Arround -->
            <div class="tp-caption tp-shape tp-shapewrapper " 
                 id="slide-901-layer-6" 
                 data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" 
                 data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" 
                            data-width="full"
                data-height="full"
                data-whitespace="nowrap"
                data-visibility="['on','on','off','off']"
     
                data-type="shape" 
                data-basealign="slide" 
                data-responsive_offset="off" 
                data-responsive="off"
                data-frames='[{"delay":50,"speed":100,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"frame":"999","to":"opacity:0;","ease":"Power3.easeIn"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
    
                style="z-index: 5;background-color:rgba(0, 0, 0, 0); border-color:#eff3f6 ;border-style:solid;border-width:50px 50px 50px 50px;"></div>
    
            <!-- Border Area -->
         
        </li>

    </ul>
    <div class="tp-bannertimer tp-bottom" style="visibility: hidden !important;"></div>	</div>
</div>