import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-project-grid',
  templateUrl: './page-project-grid.component.html',
  styleUrls: ['./page-project-grid.component.css']
})
export class PageProjectGridComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner = {
    background: "assets/images/banner/3.png",
    title: "Nominados",
    crumb: "nominados"
  }

  projects = [
    // Premio a la trayectoria
    {
      category: "cat-1",
      image: "assets/images/nominados/bebeto.jpeg",
      title: "Alberto Berbabe (BEBETO)",
      description: "Premio a la Trayectoria."
    },
    {
      category: "cat-1",
      image: "assets/images/nominados/EDDY_RAFAEL.jpg",
      title: "Eddy Rafael",
      description: "Premio a la Trayectoria."
    },
    {
      category: "cat-1",
      image: "assets/images/nominados/CHICHO SEVERINO.jpg",
      title: "Chicho Severino",
      description: "Premio a la Trayectoria."
    },
    {
      category: "cat-1",
      image: "assets/images/nominados/CHARLIE RODRIGUEZ.jpg",
      title: "Charlie Rodriguez",
      description: "Premio a la Trayectoria."
    },
    {
      category: "cat-1",
      image: "assets/images/nominados/EL GRIMGO DE LA BACHATA.jpg",
      title: "El Gringo de la bachata",
      description: "Premio a la Trayectoria."
    },
    {
      category: "cat-1",
      image: "assets/images/nominados/MALA FE.jpg",
      title: "Mala FE",
      description: "Premio a la Trayectoria."
    },
    {
      category: "cat-1",
      image: "assets/images/nominados/MONCHI CAPRICHO.jpg",
      title: "Monchi Capricho",
      description: "Premio a la Trayectoria."
    },
    {
      category: "cat-1",
      image: "assets/images/nominados/KINITO MENDEZ.jpg",
      title: "Kinito Mendez",
      description: "Premio a la Trayectoria."
    },
    {
      category: "cat-1",
      image: "assets/images/nominados/PEnA_SUAZO.jpg",
      title: "Jose Peña Suazo",
      description: "Premio a la Trayectoria."
    },
    {
      category: "cat-1",
      image: "assets/images/nominados/SILVIO MORA.jpg",
      title: "Silvio Mora",
      description: "Premio a la Trayectoria."
    },

// Fin Premio a la trayectoria

// Premio Especial

    {
      category: "cat-2",
      image: "assets/images/nominados/FELIX GRANT.jpg",
      title: "Felix Grant",
      description: "Premio Especial"
    },
    {
      category: "cat-2",
      image: "assets/images/nominados/NESTOR DIAZ.jpg",
      title: "Nestor Diaz",
      description: "Premio Especial"
    },
    {
      category: "cat-2",
      image: "assets/images/nominados/MARCEL.jpg",
      title: "Marcel",
      description: "Premio Especial"
    },
    {
      category: "cat-2",
      image: "assets/images/nominados/HECTOR CASTILLO.jpg",
      title: "Hector Castillo",
      description: "Premio Especial"
    },
    {
      category: "cat-2",
      image: "assets/images/nominados/HECTOR CASTILLO EL TIO.jpg",
      title: "Hector Castillo (EL TIO)",
      description: "Premio Especial"
    },
    {
      category: "cat-2",
      image: "assets/images/nominados/FRANKLIN  NUNEZ.jpg",
      title: "Franklin Núñez",
      description: "Premio Especial"
    },
    {
      category: "cat-2",
      image: "assets/images/nominados/Bonaerge Linares.jpg",
      title: "Bonaerge Linares",
      description: "Premio Especial"
    },
    {
      category: "cat-2",
      image: "assets/images/nominados/ROSALIN PEREZ.jpg",
      title: "Rosalin Perez",
      description: "Premio Especial"
    },
    {
      category: "cat-2",
      image: "assets/images/nominados/ARABELLY CRUZ.jpg",
      title: "Arabelly Cruz",
      description: "Premio Especial"
    },
    {
      category: "cat-2",
      image: "assets/images/nominados/ALEXIS BARRERA.jpg",
      title: "Alexis Barrera",
      description: "Premio Especial"
    },
    {
      category: "cat-2",
      image: "assets/images/nominados/YANFOURD.jpg",
      title: "Yandourd",
      description: "Premio Especial"
    },
    {
      category: "cat-2",
      image: "assets/images/nominados/PEDRO SERRANO.jpg",
      title: "Pedro Serrano",
      description: "Premio Especial"
    },
    {
      category: "cat-2",
      image: "assets/images/nominados/FRANK AJENO.jpg",
      title: "Frank Ajeno",
      description: "Premio Especial"
    },


// Fin Premio Especial

// Locutor del ano
{
  category: "cat-3",
  image: "assets/images/nominados/BONNY BAEZ.jpg",
  title: "Bonny Baez",
  description: "Locutor del Año"
},
{
  category: "cat-3",
  image: "assets/images/nominados/DIONI NOVA.jpg",
  title: "Dionicio Medrano (Dioni Nova)",
  description: "Locutor del Año"
},
{
  category: "cat-3",
  image: "assets/images/nominados/EL CONDO.jpg",
  title: "El Condo",
  description: "Locutor del Año"
},
{
  category: "cat-3",
  image: "assets/images/nominados/EL GENERAL JORGE.jpg",
  title: "El General Jorge",
  description: "Locutor del Año"
},
{
  category: "cat-3",
  image: "assets/images/nominados/RAFAEL VIDAL.jpg",
  title: "Rafael Vidal",
  description: "Locutor del Año"
},
{
  category: "cat-3",
  image: "assets/images/nominados/SANDY SANDY.jpg",
  title: "Sandy Sandy",
  description: "Locutor del Año"
},
{
  category: "cat-3",
  image: "assets/images/nominados/SANDY ALMEYDA.jpg",
  title: "Sandy Almeyda",
  description: "Locutor del Año"
},


// Fin Locutor del ano


//  Locutora del ano

    {
      category: "cat-4",
      image: "assets/images/nominados/ARACELIS MARIZAN -.jpg",
      title: "Aracelis Merizan",
      description: "Locutora del Año"
    },
    {
      category: "cat-4",
      image: "assets/images/nominados/ARLENY JAQUEZ.jpg",
      title: "Arleny Jaquez",
      description: "Locutora del Año"
    },
    {
      category: "cat-4",
      image: "assets/images/nominados/CRISMY PEREZ.jpg",
      title: "Crismy Perez",
      description: "Locutora del Año"
    },
    {
      category: "cat-4",
      image: "assets/images/nominados/Ynvelka Espino.jpg",
      title: "Ynvelka Espino",
      description: "Locutora del Año"
    },
    {
      category: "cat-4",
      image: "assets/images/nominados/MIWANNY RUIZ  SOLANO.jpg",
      title: "Miwanny Ruiz Solano",
      description: "Locutora del Año"
    },

    {
      category: "cat-4",
      image: "assets/images/nominados/ZAMIRA ESPINOSA.jpg",
      title: "Zamira Espinosa",
      description: "Locutora del Año"
    },
    {
      category: "cat-4",
      image: "assets/images/nominados/Lilivet Santana.jpg",
      title: "lilivet santana",
      description: "Locutora del Año"
    },



    // Fin Locutora del ano


// Musica Cristiana
    {
      category: "cat-5",
      image: "assets/images/nominados/Cleyri Cruz-.jpg",
      title: "Cleyri Cruz",
      description: " Musica Cristiana del Año"
    },
    {
      category: "cat-5",
      image: "assets/images/nominados/Gerson Corniel-.jpg",
      title: "Gerson Corniel",
      description: " Musica Cristiana del Año"
    },
    {
      category: "cat-5",
      image: "assets/images/nominados/MARIZI MARTINEZ.jpg",
      title: "Marizi Martinez",
      description: " Musica Cristiana del Año"
    },
    {
      category: "cat-5",
      image: "assets/images/nominados/JOSEFINA HERRERA.jpg",
      title: "Josefina Herrera",
      description: " Musica Cristiana del Año"
    },
    {
      category: "cat-5",
      image: "assets/images/nominados/PEPE CORNIEL.jpg",
      title: "Pepe Corniel",
      description: " Musica Cristiana del Año"
    },
    {
      category: "cat-5",
      image: "assets/images/nominados/PERLA FRIAS.jpg",
      title: "Perla Frias",
      description: " Musica Cristiana del Año"
    },
    {
      category: "cat-5",
      image: "assets/images/nominados/GERSON KELLY.jpg",
      title: "Gerson Kelly",
      description: " Musica Cristiana del Año"
    },

//  fin Musica Cristiana


// Periodista
    {
      category: "cat-6",
      image: "assets/images/nominados/CORNELIA  MOTA.jpg",
      title: "Cornelia Mota",
      description: "Periodista del Año"
    },
    {
      category: "cat-6",
      image: "assets/images/nominados/DANIEL VALERIO.jpg",
      title: "Daniel Valerio",
      description: "Periodista del Año"
    },

    {
      category: "cat-6",
      image: "assets/images/nominados/VICENTE VALBUENA.jpg",
      title: "Vicente Valbuena",
      description: "Periodista del Año"
    },
    {
      category: "cat-6",
      image: "assets/images/nominados/blank-profile.png",
      title: "Sujeidy Urraca",
      description: "Periodista del Año"
    },
    // {
    //   category: "cat-6",
    //   image: "assets/images/nominados/blank-profile.png",
    //   title: "Rita Lopez",
    //   description: "Periodista del Año"
    // },
    {
      category: "cat-6",
      image: "assets/images/nominados/JOSE_ALDUEY_SIERRA2.jpg",
      title: "Jose Alduey Sierra",
      description: "Periodista del Año"
    },
    {
      category: "cat-6",
      image: "assets/images/nominados/blank-profile.png",
      title: "Junior Betances",
      description: "Periodista del Año"
    },

  // Fin Periodista

  // BACHATERO DEL AÑO

     {
      category: "cat-7",
      image: "assets/images/nominados/02 ANDY ARACENA.jpg",
      title: "Andy Aracena",
      description: "Bachatero del Año"
    },
    {
      category: "cat-7",
      image: "assets/images/nominados/05 Salomon y Alejandra.jpg",
      title: "Salomon y Alejandra",
      description: "Bachatero del Año"
    },
    {
      category: "cat-7",
      image: "assets/images/nominados/03 EDWIN MANUEL EL NUEVO  REY.jpg",
      title: "Edwin Manuel",
      description: "Bachatero del Año"
    },
    {
      category: "cat-7",
      image: "assets/images/nominados/01 LUIS LA FAMA.jpg",
      title: "Luis La Fama",
      description: "Bachatero del Año"
    },
    {
      category: "cat-7",
      image: "assets/images/nominados/04 VICTOR ALMANZAR.jpg",
      title: "Victor Almanzar",
      description: "Bachatero del Año"
    },
    {
      category: "cat-7",
      image: "assets/images/nominados/KAMILOLF.jpeg",
      title: "Kamilo",
      description: "Bachatero del Año"
    },
    {
      category: "cat-7",
      image: "assets/images/nominados/07 KANO KEYS.jpg",
      title: "Kano Keys",
      description: "Bachatero del Año"
    },



  // Fin BACHATERO DEL AÑO


  // ARTISTA FEMENINA DEL AÑO

  {
    category: "cat-8",
    image: "assets/images/nominados/Laurin Novas.jpg",
    title: "Laurin Novas",
    description: "Artista Femenina del Año"
  },
  {
    category: "cat-8",
    image: "assets/images/nominados/LEYDIBELL SEGURA.jpg",
    title: "LeydiBell Segura",
    description: "Artista Femenina del Año"
  },
  {
    category: "cat-8",
    image: "assets/images/nominados/KASANDRA VARGAS.jpg",
    title: "Kasandra Vargas",
    description: "Artista Femenina del Año"
  },
  {
    category: "cat-8",
    image: "assets/images/nominados/shany brawn.jpg",
    title: "Shany Brawn",
    description: "Artista Femenina del Año"
  },
  {
    category: "cat-8",
    image: "assets/images/nominados/MAYRA BELLO.jpg",
    title: "Mayra Bello",
    description: "Artista Femenina del Año"
  },
  {
    category: "cat-8",
    image: "assets/images/nominados/LIRALDI LA DIVINA.jpg",
    title: "Liraldi la Divina",
    description: "Artista Femenina del Año"
  },
  {
    category: "cat-8",
    image: "assets/images/nominados/INEZ L.jpg",
    title: "Inez L",
    description: "Artista Femenina del Año"
  },



  //  Fin ARTISTA FEMENINA DEL AÑO

  // Artistas Plasticos del Ano
    {
      category: "cat-9",
      image: "assets/images/nominados/RIGO PERALTA.jpg",
      title: "Rigo Peralta ",
      description: "Artista Plastico del Año"
    },
    {
      category: "cat-9",
      image: "assets/images/nominados/PEDRO REYES.jpg",
      title: "Pedro Reyes ",
      description: "Artista Plastico del Año"
    },
    {
      category: "cat-9",
      image: "assets/images/nominados/NIC BRITO.jpg",
      title: "Nick Brito ",
      description: "Artista Plastico del Año"
    },
    {
      category: "cat-9",
      image: "assets/images/nominados/EZEQUIEL  JIMENEZ.jpg",
      title: "Ezequiel Jimenez ",
      description: "Artista Plastico del Año"
    },
    {
      category: "cat-9",
      image: "assets/images/nominados/HECTOR U.jpg",
      title: "Hector Ureña",
      description: "Artista Plastico del Año"
    },
    {
      category: "cat-9",
      image: "assets/images/nominados/BELGICA MEDINA.jpg",
      title: "Belgica Medina ",
      description: "Artista Plastico del Año"
    },
    {
      category: "cat-9",
      image: "assets/images/nominados/RAMON PERALTA.jpg",
      title: "Ramon Peralta",
      description: "Artista Plastico del Año"
    },
    // Fin Artistas Plasticos del Ano


    // Actor / Actriz  de Cine del Año
    {
      category: "cat-10",
      image: "assets/images/nominados/FRACES CRUZ.jpg",
      title: "Fraces Cruz",
      description: "Actor / Actriz  de Cine del Año"
    },
    {
      category: "cat-10",
      image: "assets/images/nominados/PAMELA PAYANO.jpg",
      title: "Pamela Payano",
      description: "Actor / Actriz  de Cine del Año"
    },
    {
      category: "cat-10",
      image: "assets/images/nominados/DEISY OVIEDO.jpg",
      title: "Deisy Oviedo",
      description: "Actor / Actriz  de Cine del Año"
    },
    {
      category: "cat-10",
      image: "assets/images/nominados/MILDRED DE LOS SANTOS.jpg",
      title: "Mildred de los Santos",
      description: "Actor / Actriz  de Cine del Año"
    },
    {
      category: "cat-10",
      image: "assets/images/nominados/RUBEN LOPEZ.jpg",
      title: "Ruben Lopez",
      description: "Actor / Actriz  de Cine del Año"
    },
    {
      category: "cat-10",
      image: "assets/images/nominados/ROY VALLADARES.jpg",
      title: "Roy Valladares",
      description: "Actor / Actriz  de Cine del Año"
    },
    {
      category: "cat-10",
      image: "assets/images/nominados/GABRIEL CAICEDO.jpg",
      title: "Gabriel Caicedo",
      description: "Actor / Actriz  de Cine del Año"
    },
    {
      category: "cat-10",
      image: "assets/images/nominados/WILFREDO FREDDY ZAMBRANO.jpg",
      title: "Wilfredo Freddy Zambrana",
      description: "Actor / Actriz  de Cine del Año"
    },

    // Fin Actor / Actriz  de Cine del Año

     // ACTOR DE Teatro DEL AÑO
     {
      category: "cat-11",
      image: "assets/images/nominados/yormanhostos.jpeg",
      title: "Yorman Hostos",
      description: "Actor / Actriz  de Teatro del Año"
    },
    {
      category: "cat-11",
      image: "assets/images/nominados/emir.jpeg",
      title: "Emir Cruz",
      description: "Actor / Actriz  de Teatro del Año"
    },
    {
      category: "cat-11",
      image: "assets/images/nominados/molales.jpeg",
      title: "Amneris Morales",
      description: "Actor / Actriz  de Teatro del Año"
    },
    {
      category: "cat-11",
      image: "assets/images/nominados/fiormarte.jpeg",
      title: "Fior Marte",
      description: "Actor / Actriz  de Teatro del Año"
    },
    {
      category: "cat-11",
      image: "assets/images/nominados/magacedeno.jpeg",
      title: "Maga Cedeno",
      description: "Actor / Actriz  de Teatro del Año"
    },
    {
      category: "cat-11",
      image: "assets/images/nominados/juanguzman.jpeg",
      title: "Juan Guzman",
      description: "Actor / Actriz  de Teatro del Año"
    },
      // Fin ACTOR DE Teatro DEL AÑO


      // MEDIO DIGITAL DEL AÑO

      {
        category: "cat-12",
        image: "assets/images/nominados/NOLASCO -.jpg",
        title: "Nolagko Nolasco (neoyorkinos.com)",
        description: "Medio Digital del Año"
      },

      {
        category: "cat-12",
        image: "assets/images/nominados/ARTISTIKAA-.jpg",
        title: " Gloria Morales",
        description: "Medio Digital del Año"
      },

      {
        category: "cat-12",
        image: "assets/images/nominados/Agustin de la cruz (2).jpg",
        title: "Agustin de la Cruz (soylatino.net) ",
        description: "Medio Digital del Año"
      },

      {
        category: "cat-12",
        image: "assets/images/nominados/BENNY -.jpg",
        title: "Benny Congero RD (Congerord.com) ",
        description: "Medio Digital del Año"
      },

      {
        category: "cat-12",
        image: "assets/images/nominados/AMADO Khoury - BONCHES LATINOS.jpg",
        title: "Amado Khoury",
        description: "Medio Digital del Año"
      },

      {
        category: "cat-12",
        image: "assets/images/nominados/DJ. CICLON-.jpg",
        title: "Jorge Nieto DJ. CICLON",
        description: "Medio Digital del Año"
      },

      {
        category: "cat-12",
        image: "assets/images/nominados/ZABALA -.jpg",
        title: "Jose Zabala",
        description: "Medio Digital del Año"
      },

      {
        category: "cat-12",
        image: "assets/images/nominados/DJ. 1.jpg",
        title: "Ramon Ramirez D.J 1",
        description: "Medio Digital del Año"
      },
        // Fin MEDIO DIGITAL DEL AÑO


          //CRONISTA DEPORTIVO DEL AÑO
        {
          category: "cat-13",
          image: "assets/images/nominados/GABRIEL_BARCACER.jpg",
          title: "Gabriel Barcacer",
          description: "Cronista Deportivo del Año"
        },
        {
          category: "cat-13",
          image: "assets/images/nominados/JOHN_SANG.jpg",
          title: "John Sang",
          description: "Cronista Deportivo del Año"
        },
        {
          category: "cat-13",
          image: "assets/images/nominados/MARTIN_ZAPATA.jpg",
          title: "Martin Zapata",
          description: "Cronista Deportivo del Año"
        },
        {
          category: "cat-13",
          image: "assets/images/nominados/JENNIFER_MERCEDES.jpg",
          title: "Jennifer Mercedez",
          description: "Cronista Deportivo del Año"
        },
        {
          category: "cat-13",
          image: "assets/images/nominados/FRANCISCO_RIVERA.jpg",
          title: "Francisco Rivera",
          description: "Cronista Deportivo del Año"
        },
        {
          category: "cat-13",
          image: "assets/images/nominados/tenchy_rodriguez.jpg",
          title: "Tenchy Rodriguez",
          description: "Cronista Deportivo del Año"
        },
        {
          category: "cat-13",
          image: "assets/images/nominados/KATIUSCA MARIA.jpg",
          title: "Katiuska Maria",
          description: "Cronista Deportivo del Año"
        },

        //Fin CRONISTA DEPORTIVO DEL AÑO


        // BALADISTA DE AÑO
        {
          category: "cat-14",
          image: "assets/images/nominados/TONY BRAVO -.jpg",
          title: "Tony Bravo",
          description: "Baladista del Año"
        },

        {
          category: "cat-14",
          image: "assets/images/nominados/XAVIER ENRIQUE.jpg",
          title: "Xavier Enrique",
          description: "Baladista del Año"
        },
        {
          category: "cat-14",
          image: "assets/images/nominados/REYMON DURAN.jpg",
          title: "Reymon Duran",
          description: "Baladista del Año"
        },
        {
          category: "cat-14",
          image: "assets/images/nominados/JOSE ADELIZ--.jpg",
          title: "Jose Adeliz",
          description: "Baladista del Año"
        },
        {
          category: "cat-14",
          image: "assets/images/nominados/ARISMENDY JIMENEZ.jpg",
          title: "Arismendy Jimenez",
          description: "Baladista del Año"
        },
        {
          category: "cat-14",
          image: "assets/images/nominados/MARIANO CESAR.jpg",
          title: "Mariano Cesar",
          description: "Baladista del Año"
        },
        {
          category: "cat-14",
          image: "assets/images/nominados/MINO DE AMERICA---.jpg",
          title: "Mino de America",
          description: "Baladista del Año"
        },
        {
          category: "cat-14",
          image: "assets/images/nominados/VICTOR DEL VILLAR.jpg",
          title: "Victor del Villar",
          description: "Baladista del Año"
        },

        // Fin BALADISTA DE ANO


        // COMENTARISTA DE NOTICIA DE AÑO

        {
          category: "cat-15",
          image: "assets/images/nominados/RAFAEL_DIAZ_com.jpg",
          title: "Rafael Diaz",
          description: "Comentarista de Noticia del Año"
        },
        {
          category: "cat-15",
          image: "assets/images/nominados/CORNELIO.jpg",
          title: "Cornelio Olivo",
          description: "Comentarista de Noticia del Año"
        },
        {
          category: "cat-15",
          image: "assets/images/nominados/Luis Cartagena -.jpg",
          title: "Luis Cartagena",
          description: "Comentarista de Noticia del Año"
        },
        {
          category: "cat-15",
          image: "assets/images/nominados/PEDRO AGUIAL.jpg",
          title: "Pedro Aguial (La verdar)",
          description: "Comentarista de Noticia del Año"
        },
        {
          category: "cat-15",
          image: "assets/images/nominados/Rolando_Robles.jpg",
          title: "Rolando Robles (Pensamiento Propio)",
          description: "Comentarista de Noticia del Año"
        },
        {
          category: "cat-15",
          image: "assets/images/nominados/Jose_Alduey_Sierra.jpg",
          title: "Jose Alduey Sierra (Entre Lideres)",
          description: "Comentarista de Noticia del Año"
        },
        {
          category: "cat-15",
          image: "assets/images/nominados/Samuel Sanchez.jpg",
          title: "Samuel Sanchez (Hechos y Opiniones)",
          description: "Comentarista de Noticia del Año"
        },
        // FIN COMENTARISTA DE NOTICIA DE AÑO

        // PRESENTADOR DEL AÑO
        {
          category: "cat-16",
          image: "assets/images/nominados/JOSe LUIS DE LEON (VINIL).jpg",
          title: "Jose Luis de Leon Vinyl",
          description: "Presentador del Año"
        },
        {
          category: "cat-16",
          image: "assets/images/nominados/04 MANNY RODRÍGUEZ.jpg",
          title: "Manny Rodriguez",
          description: "Presentador del Año"
        },

        {
          category: "cat-16",
          image: "assets/images/nominados/05 JOSÉ LUIS SURIEL.jpg",
          title: "Jose Luis Suriel",
          description: "Presentador del Año"
        },

        {
          category: "cat-16",
          image: "assets/images/nominados/06 Juan Jimenez.jpg",
          title: "Juan Jimenez",
          description: "Presentador del Año"
        },

        {
          category: "cat-16",
          image: "assets/images/nominados/02 Bb Swing.jpg",
          title: "BB Swing",
          description: "Presentador del Año"
        },

        {
          category: "cat-16",
          image: "assets/images/nominados/03 HAROLD DÍAZ.jpg",
          title: "Harold Diaz",
          description: "Presentador del Año"
        },

        {
          category: "cat-16",
          image: "assets/images/nominados/01 BOMBA BRITO.jpg",
          title: "Bomba Brito",
          description: "Presentador del Año"
        },

        // Fin PRESENTADOR DEL AÑO




         // PRESENTADORA DEL AÑO

         {
          category: "cat-17",
          image: "assets/images/nominados/05 YARY NIN.jpg",
          title: "Yary Nin",
          description: "Presentadora del Año"
        },
        {
          category: "cat-17",
          image: "assets/images/nominados/02 DANGELINE CONTRERAS.jpg",
          title: "Dangeline Contreras",
          description: "Presentadora del Año"
        },

        {
          category: "cat-17",
          image: "assets/images/nominados/MARIELY DE LA CRUZ.jpg",
          title: "Mariely de la Cruz",
          description: "Presentadora del Año"
        },

        {
          category: "cat-17",
          image: "assets/images/nominados/07TIARIS CABRERA.jpg",
          title: "Tiaris Cabrera",
          description: "Presentadora del Año"
        },

        {
          category: "cat-17",
          image: "assets/images/nominados/03 MARIELA GENAO.jpg",
          title: "Mariela Genao",
          description: "Presentadora del Año"
        },

        {
          category: "cat-17",
          image: "assets/images/nominados/MARIELVA HERNANDEZ ---.jpg",
          title: "Marielva Hernandez",
          description: "Presentadora del Año"
        },

        {
          category: "cat-17",
          image: "assets/images/nominados/06 YANELIS SOSA.jpg",
          title: "Yanelis Sosa",
          description: "Presentadora del Año"
        },

          // Fin PRESENTADORA DEL AÑO




          // MERENGUERO URBANO DEL AÑO

          {
            category: "cat-18",
            image: "assets/images/nominados/LUIS EL BENDITO.jpg",
            title: "Luis El Bendito",
            description: "Merengue Urbano del Año"
          },
          {
            category: "cat-18",
            image: "assets/images/nominados/CHU EL VERSATIL.jpg",
            title: "Chu El Versatil",
            description: "Merengue Urbano del Año"
          },
          {
            category: "cat-18",
            image: "assets/images/nominados/EL PRINGUI.jpg",
            title: "El Pringui",
            description: "Merengue Urbano del Año"
          },
          {
            category: "cat-18",
            image: "assets/images/nominados/EL TENAZ.jpg",
            title: "El Tenaz",
            description: "Merengue Urbano del Año"
          },
          {
            category: "cat-18",
            image: "assets/images/nominados/Max Laury.jpg",
            title: "Max Laury",
            description: "Merengue Urbano del Año"
          },
          {
            category: "cat-18",
            image: "assets/images/nominados/ROLANDO SWING.jpg",
            title: "Rolando Swing",
            description: "Merengue Urbano del Año"
          },
          {
            category: "cat-18",
            image: "assets/images/nominados/VICTOR DEL VILLAR.jpg",
            title: "Victor del Villar",
            description: "Merengue Urbano del Año"
          },
          // FIN MERENGUERO URBANO DEL AÑO
          //



          // PRODUCTOR MUSICAL DEL AÑO
          {
            category: "cat-19",
            image: "assets/images/nominados/Franklin Catano-.jpg",
            title: "Franklin Catano",
            description: "Productor Musical del Año"
          },
          {
            category: "cat-19",
            image: "assets/images/nominados/NILO PIANO.jpg",
            title: "Nilo Piano",
            description: "Productor Musical del Año"
          },{
            category: "cat-19",
            image: "assets/images/nominados/andy santana.jpg",
            title: "Andy Santana",
            description: "Productor Musical del Año"
          },{
            category: "cat-19",
            image: "assets/images/nominados/ANGEL  VASQUEZ.jpg",
            title: "Angel Vasquez",
            description: "Productor Musical del Año"
          },{
            category: "cat-19",
            image: "assets/images/nominados/LUISITO GÓMEZ.jpg",
            title: "Luisito Gomez",
            description: "Productor Musical del Año"
          },{
            category: "cat-19",
            image: "assets/images/nominados/ELVIN POLANCO.jpg",
            title: "Elvin Polanco ",
            description: "Productor Musical del Año"
          },{
            category: "cat-19",
            image: "assets/images/nominados/DADY DARYS.jpg",
            title: "Dary Dary ",
            description: "Productor Musical del Año"
          },{
            category: "cat-19",
            image: "assets/images/nominados/MANNY PI.jpg",
            title: "Manny Pi ",
            description: "Productor Musical del Año"
          },
          // Fin PRODUCTOR MUSICAL DEL AÑO

          //

          //Empresario del Ano
          {
            category: "cat-20",
            image: "assets/images/nominados/JOHANNY ULLOA-.jpg",
            title: "Johanny Ulloa",
            description: "Empresario del Año"
          },
          {
            category: "cat-20",
            image: "assets/images/nominados/MAXIMO ANDUJAR.jpg",
            title: "Maximo Andujar",
            description: "Empresario del Año"
          },
          {
            category: "cat-20",
            image: "assets/images/nominados/PIRINCHO.jpg",
            title: "Alberto Cruz (Pirincho)",
            description: "Empresario del Año"
          },
          {
            category: "cat-20",
            image: "assets/images/nominados/ANTONIO EL VALE.jpg",
            title: "Antonio el Vale ",
            description: "Empresario del Año"
          },
          {
            category: "cat-20",
            image: "assets/images/nominados/VIDAL CEDEÑO.jpg",
            title: "Vidal Cedeño ",
            description: "Empresario del Año"
          },
          {
            category: "cat-20",
            image: "assets/images/nominados/HENRY OVALLE.jpg",
            title: "Henry Ovalle ",
            description: "Empresario del Año"
          },
          {
            category: "cat-20",
            image: "assets/images/nominados/CONFESOR MARTINEZ.jpg",
            title: "Confesor Martinez ",
            description: "Empresario del Año"
          },



          //Fin Empresario del Ano




          // Merenguero del Año
          {
            category: "cat-21",
            image: "assets/images/nominados/DIONIS K-DA UNO.jpg",
            title: "Dionis K-DA uno ",
            description: "Merenguero del Año"
          },
          {
            category: "cat-21",
            image: "assets/images/nominados/JUAN CARLOS CRUZ.jpg",
            title: "Juan Carlos Cruz",
            description: "Merenguero del Año"
          },
          {
            category: "cat-21",
            image: "assets/images/nominados/Moreno Rosario.jpg",
            title: "Moreno Rosario",
            description: "Merenguero del Año"
          },
          {
            category: "cat-21",
            image: "assets/images/nominados/PHERRAMY.jpg",
            title: "Pherramy",
            description: "Merenguero del Año"
          },
          {
            category: "cat-21",
            image: "assets/images/nominados/RALY.jpg",
            title: "Raly",
            description: "Merenguero del Año"
          },
          {
            category: "cat-21",
            image: "assets/images/nominados/SINFROTERAS.jpg",
            title: "Sin Fronteras",
            description: "Merenguero del Año"
          },

          {
            category: "cat-21",
            image: "assets/images/nominados/HENRRY CASTRO.jpg",
            title: "Henry Castro",
            description: "Merenguero del Año"
          },



         // Fin Merenguero del Año



        //  SALSERO DE AÑO

        {
          category: "cat-22",
          image: "assets/images/nominados/BETO COLLADO.jpg",
          title: "Beto Collado",
          description: "Salsero del Año"
        },
        {
          category: "cat-22",
          image: "assets/images/nominados/PEDRITO LAMA.jpg",
          title: "Pedrito Lama",
          description: "Salsero del Año"
        },

        {
          category: "cat-22",
          image: "assets/images/nominados/HPeREZ.jpg",
          title: "H Perez",
          description: "Salsero del Año"
        },
        {
          category: "cat-22",
          image: "assets/images/nominados/ROBERT MORENO.jpg",
          title: "Roberto Moreno",
          description: "Salsero del Año"
        },
        {
          category: "cat-22",
          image: "assets/images/nominados/SHAWNEE TAVERAS.jpg",
          title: "Shawnee Tavera",
          description: "Salsero del Año"
        },
        {
          category: "cat-22",
          image: "assets/images/nominados/LUIS ACOSTA EL VIEJO LOBO.jpg",
          title: "Luis Acosta (El viejo Lobo)",
          description: "Salsero del Año"
        },
        {
          category: "cat-22",
          image: "assets/images/nominados/IRVING NOBOA.jpg",
          title: "Irving Noboa",
          description: "Salsero del Año"
        },
        {
          category: "cat-22",
          image: "assets/images/nominados/blank-profile.png",
          title: "Felix Manuel",
          description: "Salsero del Año"
        },

        //  FIN SALSERO DE AÑO




        //  FIN Tipico DE AÑO

        {
          category: "cat-23",
          image: "assets/images/nominados/CHINO AGUACATE-.jpg",
          title: "Chino Aguacate",
          description: "Tipico del Año"
        },
        {
          category: "cat-23",
          image: "assets/images/nominados/blank-profile.png",
          title: "Banda Solida",
          description: "Tipico del Año"
        },
        {
          category: "cat-23",
          image: "assets/images/nominados/GRUPO D AHORA.jpg",
          title: "Grupo D Ahora",
          description: "Tipico del Año"
        },
        {
          category: "cat-23",
          image: "assets/images/nominados/GRUPO NEXO.jpg",
          title: "Grupo Nexo",
          description: "Tipico del Año"
        },
        {
          category: "cat-23",
          image: "assets/images/nominados/NICOL PEÑA.jpg",
          title: "Nicol Peña",
          description: "Tipico del Año"
        },
        {
          category: "cat-23",
          image: "assets/images/nominados/ROBERT VARGAS.jpg",
          title: "Robert Vargas",
          description: "Tipico del Año"
        },
        {
          category: "cat-23",
          image: "assets/images/nominados/URBANDA.jpg",
          title: "Urbanda",
          description: "Tipico del Año"
        },

        //  FIN Tipico DE AÑO


        // PROG. DE FIN DE SEMANA DE AÑO

        {
          category: "cat-24",
          image: "assets/images/nominados/GERONIMO SHOW.jpg",
          title: "Geronimo Show, Roberto Geronimo",
          description: "Prog. Fin de Semana del Año"
        },
        {
          category: "cat-24",
          image: "assets/images/nominados/EL KAN INTERNACIONAL.jpg",
          title: "El Kan Internacional Abel Rodriguez",
          description: "Prog. Fin de Semana del Año"
        },
        {
          category: "cat-24",
          image: "assets/images/nominados/EL SHOW DE LOS GRANDES.jpg",
          title: "El Show de los Grandes, Fran Giron",
          description: "Prog. Fin de Semana del Año"
        },
        {
          category: "cat-24",
          image: "assets/images/nominados/LA VELLONERA.jpg",
          title: "La Vellonera, Adalberto Grullon",
          description: "Prog. Fin de Semana del Año"
        },
        {
          category: "cat-24",
          image: "assets/images/nominados/PENSAMIENTO PROPIO.jpg",
          title: "Pensamiento Propio, Robles el Jabali",
          description: "Prog. Fin de Semana del Año"
        },
        {
          category: "cat-24",
          image: "assets/images/nominados/SOMOS DE QUISQUEYA.jpg",
          title: "Somos de Quisqueya, Jenny Gomez",
          description: "Prog. Fin de Semana del Año"
        },
        {
          category: "cat-24",
          image: "assets/images/nominados/TOCALO YOU.jpg",
          title: "Tocalo You, Jose Luis Suriel",
          description: "Prog. Fin de Semana del Año"
        },



        // Fin PROG. DE FIN DE SEMANA DE AÑO

        // CAMAROGRAFO DEL AÑO

        {
          category: "cat-25",
          image: "assets/images/nominados/Juan Corniel El Patron.jpg",
          title: "Juan Corniel (El Patron)",
          description: "Camarografo del Año"
        },

        {
          category: "cat-25",
          image: "assets/images/nominados/JULIO COLON.jpg",
          title: "Julio Colon",
          description: "Camarografo del Año"
        },


        {
          category: "cat-25",
          image: "assets/images/nominados/MANNY ZOOM.jpg",
          title: "Manny Zoom",
          description: "Camarografo del Año"
        },


        {
          category: "cat-25",
          image: "assets/images/nominados/MANUEL CIERRA.jpg",
          title: "Manuel Cierra",
          description: "Camarografo del Año"
        },


        {
          category: "cat-25",
          image: "assets/images/nominados/Tony Olivares.jpg",
          title: "Tony Olivare1s",
          description: "Camarografo del Año"
        },


        {
          category: "cat-25",
          image: "assets/images/nominados/Pedro Martínez.jpg",
          title: "Pedro Martinez",
          description: "Camarografo del Año"
        },


        {
          category: "cat-25",
          image: "assets/images/nominados/LIANY FERRERA.jpg",
          title: "Liany Ferrera",
          description: "Camarografo del Año"
        },

        // Fin CAMAROGRAFO DEL AÑO



        // COMEDIANTE DEL AÑO
        {
          category: "cat-26",
          image: "assets/images/nominados/HORACIO AMPARO.jpg",
          title: "Horacio Amparo",
          description: "Comediante del Año"
        },
        {
          category: "cat-26",
          image: "assets/images/nominados/Raphy Almonte.jpg",
          title: "Raphy Almonte",
          description: "Comediante del Año"
        },
        {
          category: "cat-26",
          image: "assets/images/nominados/RAFAEL ALDUEY.jpg",
          title: "Rafael Alduey",
          description: "Comediante del Año"
        },
        {
          category: "cat-26",
          image: "assets/images/nominados/Ycell Gomez.jpg",
          title: "Ycell Gomez",
          description: "Comediante del Año"
        },
        {
          category: "cat-26",
          image: "assets/images/nominados/PIPE GARCIA VIZCAINO.jpg",
          title: "Pipe Garcia Vizcaino",
          description: "Comediante del Año"
        },
        {
          category: "cat-26",
          image: "assets/images/nominados/03 Octavio De La Rosa.jpg",
          title: "Octavio de la Rosa",
          description: "Comediante del Año"
        },
        {
          category: "cat-26",
          image: "assets/images/nominados/02 Jose Garcia Quija.jpg",
          title: "Jose Garcia Quija",
          description: "Comediante del Año"
        },



        // FIN COMEDIANTE DEL AÑO

        // URBANO DE AÑO
        {
          category: "cat-27",
          image: "assets/images/nominados/ANNA_D.jpg",
          title: "Anna D",
          description: "Urbano del Año"
        },
        {
          category: "cat-27",
          image: "assets/images/nominados/KALI TAINO.jpg",
          title: "Kali Taino",
          description: "Urbano del Año"
        }, {
          category: "cat-27",
          image: "assets/images/nominados/JUANCKY LA DIFERENCIA.jpg",
          title: "Juancky La Diferencia",
          description: "Urbano del Año"
        }, {
          category: "cat-27",
          image: "assets/images/nominados/Grullon el lider.jpg",
          title: "Grullon El Lider",
          description: "Urbano del Año"
        }, {
          category: "cat-27",
          image: "assets/images/nominados/NATYSHA- OJO.jpg",
          title: "Natysha",
          description: "Urbano del Año"
        }, {
          category: "cat-27",
          image: "assets/images/nominados/JARXIEL.jpg",
          title: "Jarxiel",
          description: "Urbano del Año"
        }, {
          category: "cat-27",
          image: "assets/images/nominados/PAPa CHECHE.jpg",
          title: "Papa Cheche",
          description: "Urbano del Año"
        }, {
          category: "cat-27",
          image: "assets/images/nominados/SHARKY EL TIBURON.jpg",
          title: "Sharky el Tiburon",
          description: "Urbano del Año"
        },


        // FIN URBANO DE AÑO

        // PRODUCTOR TV DE ANO
        {
          category: "cat-28",
          image: "assets/images/nominados/ABIGAIL TOBAR.jpg",
          title: "Abigail Tobar",
          description: "Productor de TV del Año"
        },
        {
          category: "cat-28",
          image: "assets/images/nominados/CARLO HILARIO-.jpg",
          title: "Carlo Hilario",
          description: "Productor de TV del Año"
        },
        {
          category: "cat-28",
          image: "assets/images/nominados/LIVI MENDEZ.jpg",
          title: "Livi Mendez",
          description: "Productor de TV del Año"
        },
        {
          category: "cat-28",
          image: "assets/images/nominados/EDY EREDIA.jpg",
          title: "Edy Eredia",
          description: "Productor de TV del Año"
        },
        {
          category: "cat-28",
          image: "assets/images/nominados/CAROLINA MARTINEZ.jpg",
          title: "Carolina Martinez",
          description: "Productor de TV del Año"
        },
        {
          category: "cat-28",
          image: "assets/images/nominados/TIGUERITO.jpg",
          title: "Tiguerito",
          description: "Productor de TV del Año"
        },
        {
          category: "cat-28",
          image: "assets/images/nominados/YAMIRKA ESTRELLA-.jpg",
          title: "Yamirka Estrella",
          description: "Productor de TV del Año"
        },

        // FIN PRODUCTOR TV DE ANO


        // Programa Diario de Variedades del Ano

        {
          category: "cat-29",
          image: "assets/images/nominados/INO CONTIGO.jpg",
          title: "Ino Contigo",
          description: "Prog. Diario de Variedades del Año"
        },
        {
          category: "cat-29",
          image: "assets/images/nominados/NOSOTROS EN USA.jpg",
          title: "Nosotros en USA",
          description: "Prog. Diario de Variedades del Año"
        },
        {
          category: "cat-29",
          image: "assets/images/nominados/OPCION NEW YORK.jpg",
          title: "Opcion New York",
          description: "Prog. Diario de Variedades del Año"
        },
        {
          category: "cat-29",
          image: "assets/images/nominados/TODO INCLUIDO.jpg",
          title: "Todo Incluido",
          description: "Prog. Diario de Variedades del Año"
        },
        {
          category: "cat-29",
          image: "assets/images/nominados/LA HORA DEL SWING.jpg",
          title: "La Hora del Swing",
          description: "Prog. Diario de Variedades del Año"
        },
        {
          category: "cat-29",
          image: "assets/images/nominados/LEVANTATE.jpg",
          title: "Levantate New York",
          description: "Prog. Diario de Variedades del Año"
        },





        // Fin Programa Diario de Variedades del Ano

        //


  ]
}
