<div class="section-full project-1-outer-main p-t80">
        
    <div class="container">
    
        <div class="wt-separator-two-part bg-white">
            <div class="row wt-separator-two-part-row">
                <div class="col-lg-6 col-md-6 wt-separator-two-part-left">
                    <!-- TITLE START-->
                    <div class="section-head left wt-small-separator-outer">
                        <div class="wt-small-separator site-text-primary">
                            <div  class="sep-leaf-left"></div>
                            <div>{{data.title}}</div>
                            <div  class="sep-leaf-right"></div>
                        </div>
                        <h2>{{data.subtitle}}</h2>
                    </div>
                    <!-- TITLE END-->
                </div>
                <div class="col-lg-6 col-md-6 wt-separator-two-part-right text-left">
                    <p>{{data.description}}</p>
                    <a routerLink="/products/product" class="site-button-link">Read More</a>
                </div>
            </div>
        </div>
        
    </div>    

    
     <div class="project-1-outer-section bg-gray bg-center" style="background-image:url(assets/images/background/bg-1.png)">
        <div class="container">
            <!-- PROJECT SLIDER START -->
            <div class="section-content">
                <div class="project-1-outer clearfix">
                    <div class="project-1-left">
                        <div class="crane-image-section">
                            <img src="{{data.image}}" alt="">
                        </div>
                    </div>
                        
                        
                    <div class="project-1-right">
                        <div class="owl-carousel project-1-slider owl-btn-top-right long-arrow-next-prev">
                            
                            <div class="item" *ngFor="let object of data.projects">
                                <div class="pproject-1-inner-block clearfix">
                                    <div class="project-1-inner-media">
                                        <div class="project-1-inner-pic">
                                            <img src="{{object.image}}" alt="">
                                        </div>
                                    </div>
                                    <div class="project-1-inner-info">
                                        <h4 class="site-text-primary">{{object.title}}</h4>
                                        <h3 class="wt-title">{{object.subtitle}}</h3>
                                        <p>{{object.description}}</p>
                                        <a routerLink="/products/product-detail" class="site-button site-btn-effect">More Detail</a>
                                    </div>
                                </div>
                            </div> 
                                                                                                                                                            
                        </div> 
                    </div>
                                                
                </div>
            </div>
        </div>
    </div>

</div>