<div class="section-full p-t80 p-b70 overlay-wraper bg-no-repeat bg-bottom-left bg-cover services-main-section" style="background-image:url(assets/images/background/bg-3.png)">
    <div class="overlay-main site-bg-secondry opacity-08"></div>

    <div class="section-content services-section-content">
        <div class="row">
            <div class="col-xl-6 col-lg-12 col-md-12">
                <div class="services-section-content-left">
                    <!-- TITLE START-->
                    <div class="left wt-small-separator-outer text-white">
                        <div class="wt-small-separator site-text-primary">
                            <div  class="sep-leaf-left"></div>
                            <div>{{data.title}}</div>
                            <div  class="sep-leaf-right"></div>
                        </div>
                        <h2>{{data.subtitle}}</h2>

                        <p>
                            {{data.description}}
                        </p>
                        <!-- <a routerLink="/other/contact1" class="site-button site-btn-effect">Contact Us</a> -->
                    </div>
                    <!-- TITLE END-->
                </div>
            </div>

            <div class="col-xl-6 col-lg-12 col-md-12">
                <div class="services-section-content-right">
                    <div class="owl-carousel services-slider owl-btn-vertical-center">

                        <div class="item" *ngFor="let object of data.services">
                            <div class="wt-box service-box-1 bg-white">

                                <div class="service-box-title title-style-2 site-text-secondry">
                                    <span  class="s-title-one">{{object.title}}</span>
                                    <!-- <span  class="s-title-two">Gas Engineering</span>      -->
                                </div>
                                <div class="service-box-content">
                                    <!-- <p>{{object.description}}</p> -->
                                    <img src="{{object.image}}"  >
                                    <a href="{{object.pagelink}}" target="_blank" class="site-button-link">M&aacute;s Info.</a>
                                </div>

                                <!-- <div class="wt-icon-box-wraper">
                                    <div class="wt-icon-box-md site-bg-primary">
                                        <span class="icon-cell text-white"><i class="{{object.icon}}"></i></span>
                                    </div>
                                    <div class="wt-icon-number"><span>{{object.serial}}</span></div>
                                </div> -->

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
