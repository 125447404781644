import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-team-single',
  templateUrl: './page-team-single.component.html',
  styleUrls: ['./page-team-single.component.css']
})
export class PageTeamSingleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner = {
    background: "assets/images/team/dariocolome.jpg",
    title: "Dario Colome",
    crumb: "Biografia"
  }

  about = {
    image: "assets/images/team/dariocolome.jpg",
    name: "Dario Colome",
    speciality: "CEO ",
    description1: "Darío Colomé nace el 13 de enero 1968 en Santo Domingo, capital de la República Dominicana,es un reconocido empresario, productor y promotor artístico radicado en la última década en la ciudad de Nueva York, donde ha cosechado gran éxito.",
    description2: "Este destacado empresario de la música ha realizado un trabajo impecable por más de tres décadas en los Estados Unidos y América Latina, siendo considerado uno de los productores artístico de mayor prestigio en la industria musical.",
    description3: "Es su empresa Producciones Colomé que por décadas ha proyectado diversas figuras del arte y la música, y su liderazgo en el manejo de talentos artísticos en el proceso de grabación lo han ubicado como un certero estratega.",
    description4: "Desde artistas de la talla de Pablo Martínez,Juan Manuel,Carlos Manuel El Zafiro, La Migra, Musicólogo, Omar La Chercha, Masa y su BigBand, entre otros.",
    description5: "Antes de que naciera su empresa, laboró para el sello disquero “Juan&Nelson(J&N)”, en el que trabajó en la promoción de los cantantes: Kinito Méndez, Alex Bueno, Luis Vargas, La BandaGorda, Frank Reyes, Zafra Negra, Yoscar Sarante, Grupo Wao, Rockabanda, La Makina y Rikarena.",
    description6: "Por su desarrollo y experiencia en el mundo de la promoción artística, pasa a formar parte de la casa disquera “Caminante Récords”, de la mano del empresario Vidal Cedeño, En la empresa disquera “Caminante Records”, estuvo trabajando para los artistas: Fernando Villalona, Julián Oro Duro, Tulile, Ravel, Fray Martínez y el Grupo Tamarindo.",
    description7: "Durante un período y desde la ciudad de Nueva York, se desempeña como encargado de marketing y ventas para la empresa Telemicro Internacional, del Grupo Telemicro de la República Dominicana.",
    description8: "Lo más distintivo del productor dominicano Darío Colomé, es el aporte que realiza a grandes nombres del arte musical popular dominicano y de otras latitudes, con legado marcado con el éxito para la industria.",
    description9: "En la comunicación y específicamente en la televisión, Colomé actualmente produce el espacio “Al Ritmo de la Noche”,con un gran equipo de comunicadores que ha posicionado dicha producción en los primeros lugares de audiencia en la franja de lunes a viernes en horario de 9:00 a 10:00 de la noche.",
    description10: "Propietario de la emisora “Búsqueda 13”, el empresario y productor entrega cada semana el espacio radial “La Vellonera de Colomé”. Es el ideólogo y productor general de los Premios Primavera que se realizan cada año en la ciudad de Nueva York con excelente aceptación.   ",
    description11: "Para Darío Colomé no hay límites y siempre emprende algo nuevo con lo que proyecta su originalidad y convirte sus proyectos en éxitos.",
    position: "Empresario artistico",
    level: "High",
    experience: "Mas de 3 Decadas",
    phone: "+1 347-636-2126",
    email: "produccionescolome@hotmail.com "
  }
}
