<div class="section-full welcome-section-outer">
    <div class="welcome-section-top bg-white p-tb80">
        <div class="container">
            <div class="row">

                <div class="col-lg-6 col-md-12">
                    <div class="img-colarge">
                        <div class="colarge-1"><img src="{{data.image}}" alt="" class="slide-righ"></div>
                        <div class="since-year-outer"><div class="since-year"><span>Desde</span><strong>{{data.since}}</strong></div></div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="welcom-to-section">
                        <!-- TITLE START-->
                        <div class="left wt-small-separator-outer">
                            <div class="wt-small-separator site-text-primary">
                                <div  class="sep-leaf-left"></div>
                                <div>{{data.title}}</div>
                                <div  class="sep-leaf-right"></div>
                            </div>
                        </div>
                        <h2>{{data.subtitle}}</h2>
                        <!-- TITLE END-->
                        <ul class="site-list-style-one">
                            <li *ngFor="let service of data.services">{{service.value}}</li>
                        </ul>

                        <p>{{data.description}}</p>

                        <div class="welcom-to-section-bottom d-flex justify-content-between">
                            <div class="welcom-btn-position"><a routerLink="/about/about1" class="site-button-secondry site-btn-effect">M&aacute;s Inf.</a></div>
                            <!-- <div class="welcom-sign-pic"><img src="{{data.sign}}" alt=""></div> -->
                            <div class="welcom-sign-info"><strong>{{data.authorized}}</strong><span>( {{data.post}} )</span></div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
