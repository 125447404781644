import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-services1',
  templateUrl: './page-services1.component.html',
  styleUrls: ['./page-services1.component.css']
})
export class PageServices1Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner = {
    background: "assets/images/banner/2.jpg",
    title: "Services 1",
    crumb: "Services 1"
  }

  services = {
    title: "The Best Industry services",
    subtitle: "High Performance Services For Multiple Insdustries And Technologies!",
    services: [
      {
        title1: "Oil &",
        title2: "Gas Engineering",
        description: "Progressively maintain extensive infomediaries via extensible nich. Capitalize on low hanging fruit.",
        icon: "flaticon-industry",
        count: "01",
        pageLink: "s-oilgas"
      },
      {
        title1: "Mechanical",
        title2: "Engineering",
        description: "Progressively maintain extensive infomediaries via extensible nich. Capitalize on low hanging fruit.",
        icon: "flaticon-conveyor",
        count: "02",
        pageLink: "s-mechanical"
      },
      {
        title1: "Automotive",
        title2: "Manufacturing",
        description: "Progressively maintain extensive infomediaries via extensible nich. Capitalize on low hanging fruit.",
        icon: "flaticon-robotic-arm",
        count: "03",
        pageLink: "s-automotive"
      },
      {
        title1: "Chemical",
        title2: "Research",
        description: "Progressively maintain extensive infomediaries via extensible nich. Capitalize on low hanging fruit.",
        icon: "flaticon-oil",
        count: "04",
        pageLink: "s-chemical"
      },
      {
        title1: "Agricultural",
        title2: "Automation",
        description: "Progressively maintain extensive infomediaries via extensible nich. Capitalize on low hanging fruit.",
        icon: "flaticon-scythe",
        count: "05",
        pageLink: "s-agricultural"
      },
      {
        title1: "Civil",
        title2: "Engineering",
        description: "Progressively maintain extensive infomediaries via extensible nich. Capitalize on low hanging fruit.",
        icon: "flaticon-helmet",
        count: "06",
        pageLink: "s-civil"
      }
    ]
  }

  plans = [
    {
      price: "29",
      plan: "Basic",
      description: "Tempo sit amet ipsum dolor, consecter adipisi cing elit, lorem do incididu eiusmod tempor dolor"
    },
    {
      price: "49",
      plan: "Standard",
      description: "Tempo sit amet ipsum dolor, consecter adipisi cing elit, lorem do incididu eiusmod tempor dolor"
    },
    {
      price: "99",
      plan: "Advanced",
      description: "Tempo sit amet ipsum dolor, consecter adipisi cing elit, lorem do incididu eiusmod tempor dolor"
    }
  ]

  testimonials = {
    title: "What our client say",
    subtitle: "Happy WIth Customers & Clients",
    bgImage: "assets/images/background/bg-map.png",
    bgClass: "bg-center bg-gray",
    quotes: [
      {
        quote: "This is not just another nail salon! These ladies are super talented! My nails have never looked/felt more amazing!! the environment here is so happy and cheery!",
        image: "assets/images/testimonials/pic1.jpg",
        by: "Malcolm Franzcrip",
        post: "Contractor"
      },
      {
        quote: "This is not just another nail salon! These ladies are super talented! My nails have never looked/felt more amazing!! the environment here is so happy and cheery!",
        image: "assets/images/testimonials/pic2.jpg",
        by: "Malcolm Franzcrip",
        post: "Contractor"
      },
      {
        quote: "This is not just another nail salon! These ladies are super talented! My nails have never looked/felt more amazing!! the environment here is so happy and cheery!",
        image: "assets/images/testimonials/pic3.jpg",
        by: "Malcolm Franzcrip",
        post: "Contractor"
      },
      {
        quote: "This is not just another nail salon! These ladies are super talented! My nails have never looked/felt more amazing!! the environment here is so happy and cheery!",
        image: "assets/images/testimonials/pic4.jpg",
        by: "Malcolm Franzcrip",
        post: "Contractor"
      }
    ]
  }

  vision = {
    title: "Vision & Mission Statement",
    subtitle: "Serving Impressive List Of Long Term Clients!",
    description: "Our worldwide presence ensures timeliness, cost efficiency and compliance adherence required to ensure your timelines are met. Serving with experience aNd expertise in multiple industries, as one of the World's leading Corporation!",
    videoLink: "https://www.youtube.com/watch?v=fgExvIUYg5w",
    quote: "Many of Our SELF registered employees are requested an main preferred temporary staff when all service",
    quoteBy: "Farnandoz Biki, CEO"
  }
}
