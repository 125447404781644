<div class="page-wraper">

    <!-- HEADER START -->
    <!-- <app-section-header2></app-section-header2> -->
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">

        <!-- INNER PAGE BANNER -->
        <app-section-inner-banner [data]="banner"></app-section-inner-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- OUR TEAM SINGLE SECTION START -->
        <div class="section-full p-t80 p-b50 bg-gray">
          <div class="container">
            <div class="section-content">

                    <div class="section-content">
                        <div class="row justify-content-center">
                            <div class="col-lg-4 col-md-4 m-b30">
                                <div class="wt-team-1">

                                    <div class="wt-media">
                                        <img src="{{about.image}}" alt="">
                                        <div class="team-social-center">
                                            <ul class="team-social-bar">
                                                <li><a target="_blank" href="https://www.facebook.com/dariocolomee/"><i class="fa fa-facebook"></i></a></li>
                                                <li><a target="_blank" href="https://twitter.com/colomedario"><i class="fa fa-twitter"></i></a></li>
                                                <li><a target="_blank" href="https://www.instagram.com/dariocolome/"><i class="fa fa-instagram"></i></a></li>
                                                <li><a target="_blank" href="https://www.linkedin.com/in/dario-colom%C3%A9-52ba64248/"><i class="fa fa-linkedin"></i></a></li>
                                            </ul>

                                        </div>
                                    </div>

                                    <div class="wt-info p-a30  p-b0">
                                        <div class="team-detail  text-center">
                                            <h3 class="m-t0 team-name"><a class="site-text-secondry">{{about.name}}</a></h3>
                                            <span class="title-style-2 team-position site-text-primary">{{about.speciality}}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-lg-8 col-md-8 m-b30">
                                <div class="wt-team-1-single">
                                    <h3 class="m-t0">Dario Colome</h3>
                                    <p>{{about.description1}}</p>
                                    <p>{{about.description2}}</p>
                                    <p>{{about.description3}}</p>
                                    <p>{{about.description4}}</p>
                                    <p>{{about.description5}}</p>
                                    <p>{{about.description6}}</p>
                                    <p>{{about.description7}}</p>
                                    <p>{{about.description8}}</p>
                                    <p>{{about.description9}}</p>
                                    <p>{{about.description10}}</p>
                                    <p>{{about.description11}}</p>

                                    <ul class="wt-team-1-single-info bg-white">
                                        <li><span>Profesion</span><span>{{about.position}}</span></li>
                                        <!-- <li><span>Level</span><span>{{about.level}}</span></li> -->
                                        <li><span>Experiencia</span><span>{{about.experience}}</span></li>
                                        <li><span>Telf.</span><span>{{about.phone}}</span></li>
                                        <li><span>Email</span><span>{{about.email}}</span></li>
                                    </ul>

                                </div>
                            </div>

                        </div>
                    </div>

            </div>
        </div>
        </div>


        <!-- OUR TEAM SINGLE SECTION END -->

    
    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <!-- <app-section-footer1></app-section-footer1> -->
    <!-- FOOTER END -->

</div>

<app-loader></app-loader>
