import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-footer1',
  templateUrl: './section-footer1.component.html',
  styleUrls: ['./section-footer1.component.css']
})
export class SectionFooter1Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
