<div class="section-full p-t80 p-b80 bg-gray-light">
    <div class="container">
        <!-- TITLE START-->
        <div class="section-head center wt-small-separator-outer text-center">
            <div class="wt-small-separator site-text-primary">
                <div  class="sep-leaf-left"></div>
                <div>{{data.title}}</div>
                <div  class="sep-leaf-right"></div>
            </div>
            <h2>{{data.subtitle}}</h2>
        </div>
        <!-- TITLE END--> 

       <div class="section-content">
            <div class="owl-carousel services-style-new owl-btn-vertical-center">
            <!-- COLUMNS 1 --> 
            <div class="item" *ngFor="let object of data.services">
                    <div class="wt-box service-box-1-new bg-white">
                        <div class="wt-icon-box-wraper-new">
                            <div class="icon-xl inline-icon">
                                <span class="icon-cell site-text-primary"><i class="{{object.icon}}"></i></span>
                            </div>
                            <div class="wt-icon-number-new"><span>{{object.count}}</span></div>
                        </div>   
                                                                        
                        <div class="service-box-title-new title-style-2 site-text-secondry">
                            <span class="s-title-one">{{object.title}}</span>
                        </div>
                        
                        <div class="service-box-content-new">
                            <p>{{object.description}}</p>

                        </div>
                        <div class="service-box-new-link">
                            <a routerLink="/services/s-mechanical" class="site-button-link">Read More</a>
                        </div>
                        
                    </div>
            </div>
    

        </div>
                                                                        
       </div>
   
   </div>
</div>