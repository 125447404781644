<div class="page-wraper">


  <!-- HEADER START -->
  <!-- <app-section-header2></app-section-header2> -->
  <!-- HEADER END -->

  <!-- CONTENT START -->
  <div class="page-content">

      <!-- INNER PAGE BANNER -->
      <app-section-inner-banner [data]="banner"></app-section-inner-banner>
      <!-- INNER PAGE BANNER END -->

      <!-- SECTION CONTENTG START -->

         <!-- CONTACT FORM -->
       <div class="section-full  p-t80 p-b50 bg-cover" style="background-image:url(assets/images/background/bg-contact.jpg)">
          <div class="section-content">
              <div class="container">
                  <div class="contact-one">
                      <!-- CONTACT FORM-->
                      <div class="row  d-flex justify-content-center flex-wrap">

                          <div class="col-lg-6 col-md-6 m-b30">
                              <form  class="cons-contact-form" method="post" action="../../../handlers/form-handler2.php">
                                  <!-- TITLE START -->
                                  <div class="section-head left wt-small-separator-outer">
                                      <div class="wt-small-separator site-text-primary">
                                          <div class="sep-leaf-left"></div>
                                          <div>Formulario de Contacto </div>
                                          <div class="sep-leaf-right"></div>
                                      </div>
                                      <h2>Contactenos</h2>
                                  </div>
                                  <!-- TITLE END -->

                                  <div class="row">
                                     <div class="col-lg-12 col-md-12">
                                          <div class="form-group">
                                              <input name="username" type="text" required class="form-control" placeholder="Nombre">
                                          </div>
                                      </div>

                                      <div class="col-lg-12 col-md-12">
                                          <div class="form-group">
                                             <input name="email" type="text" class="form-control" required placeholder="Email">
                                          </div>
                                      </div>

                                      <div class="col-lg-12 col-md-12">
                                          <div class="form-group">
                                              <input name="phone" type="text" class="form-control" required placeholder="telefono">
                                           </div>
                                      </div>

                                      <div class="col-lg-12 col-md-12">
                                          <div class="form-group">
                                               <input name="subject" type="text" class="form-control" required placeholder="Asunto">
                                           </div>
                                      </div>

                                      <div class="col-md-12">
                                          <div class="form-group">
                                             <textarea name="message" class="form-control" rows="4" placeholder="Mensaje"></textarea>
                                           </div>
                                      </div>

                                     <div class="col-md-12">
                                          <button type="submit" class="site-button site-btn-effect">Enviar</button>
                                      </div>

                                  </div>
                             </form>
                          </div>

                          <div class="col-lg-6 col-md-6 m-b30">
                              <div class="contact-info">
                                  <div class="contact-info-inner">

                                      <!-- TITLE START-->
                                      <div class="section-head left wt-small-separator-outer">
                                          <div class="wt-small-separator site-text-primary">
                                              <div class="sep-leaf-left"></div>
                                              <div>Informacion deContacto </div>
                                              <div class="sep-leaf-right"></div>
                                          </div>
                                          <h2>Vias de Contacto</h2>
                                      </div>
                                      <!-- TITLE END-->

                                      <div class="contact-info-section" style="background-image:url(assets/images/background/bg-map2.png);">

                                              <div class="wt-icon-box-wraper left m-b30">

                                                  <div class="icon-content">
                                                      <h3 class="m-t0 site-text-primary">Telefono</h3>
                                                      <p>+1 347-636-2126</p>
                                                  </div>
                                              </div>

                                              <div class="wt-icon-box-wraper left m-b30">

                                                  <div class="icon-content">
                                                      <h3 class="m-t0 site-text-primary">Email</h3>
                                                      <p>produccionescolome@hotmail.com </p>
                                                  </div>
                                              </div>

                                              <div class="wt-icon-box-wraper left m-b30">

                                                  <div class="icon-content">
                                                      <h3 class="m-t0 site-text-primary">Direccion</h3>
                                                      <p>New York, NY, USA</p>
                                                  </div>
                                              </div>


                                      </div>

                                  </div>
                              </div>
                          </div>

                      </div>
                  </div>

              </div>
          </div>
      </div>


  </div>
  <!-- CONTENT END -->

  <!-- FOOTER START -->
  <!-- <app-section-footer1></app-section-footer1> -->
  <!-- FOOTER END -->

</div>

<app-loader></app-loader>
