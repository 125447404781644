import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-project-masonry',
  templateUrl: './page-project-masonry.component.html',
  styleUrls: ['./page-project-masonry.component.css']
})
export class PageProjectMasonryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner = {
    background: "assets/images/banner/3.png",
    title: "Ganadores",
    crumb: "ganadores"
  }

  projects = [
    // Premio a la trayectoria
    // {
    //   category: "cat-1",
    //   image: "assets/images/nominados/bebeto.jpeg",
    //   title: "Alberto Berbabe (BEBETO)",
    //   description: "Premio a la Trayectoria."
    // },
    // {
    //   category: "cat-1",
    //   image: "assets/images/nominados/EDDY_RAFAEL.jpg",
    //   title: "Eddy Rafael",
    //   description: "Premio a la Trayectoria."
    // },
    // {
    //   category: "cat-1",
    //   image: "assets/images/nominados/CHICHO SEVERINO.jpg",
    //   title: "Chicho Severino",
    //   description: "Premio a la Trayectoria."
    // },
    // {
    //   category: "cat-1",
    //   image: "assets/images/nominados/CHARLIE RODRIGUEZ.jpg",
    //   title: "Charlie Rodriguez",
    //   description: "Premio a la Trayectoria."
    // },
    // {
    //   category: "cat-1",
    //   image: "assets/images/nominados/EL GRIMGO DE LA BACHATA.jpg",
    //   title: "El Gringo de la bachata",
    //   description: "Premio a la Trayectoria."
    // },
    // {
    //   category: "cat-1",
    //   image: "assets/images/nominados/MALA FE.jpg",
    //   title: "Mala FE",
    //   description: "Premio a la Trayectoria."
    // },
    // {
    //   category: "cat-1",
    //   image: "assets/images/nominados/MONCHI CAPRICHO.jpg",
    //   title: "Monchi Capricho",
    //   description: "Premio a la Trayectoria."
    // },
    // {
    //   category: "cat-1",
    //   image: "assets/images/nominados/KINITO MENDEZ.jpg",
    //   title: "Kinito Mendez",
    //   description: "Premio a la Trayectoria."
    // },
    // {
    //   category: "cat-1",
    //   image: "assets/images/nominados/PEnA_SUAZO.jpg",
    //   title: "Jose Peña Suazo",
    //   description: "Premio a la Trayectoria."
    // },
    // {
    //   category: "cat-1",
    //   image: "assets/images/nominados/SILVIO MORA.jpg",
    //   title: "Silvio Mora",
    //   description: "Premio a la Trayectoria."
    // },

// Fin Premio a la trayectoria

// Premio Especial

    // {
    //   category: "cat-2",
    //   image: "assets/images/nominados/FELIX GRANT.jpg",
    //   title: "Felix Grant",
    //   description: "Premio Especial"
    // },
    // {
    //   category: "cat-2",
    //   image: "assets/images/nominados/NESTOR DIAZ.jpg",
    //   title: "Nestor Diaz",
    //   description: "Premio Especial"
    // },
    // {
    //   category: "cat-2",
    //   image: "assets/images/nominados/MARCEL.jpg",
    //   title: "Marcel",
    //   description: "Premio Especial"
    // },
    // {
    //   category: "cat-2",
    //   image: "assets/images/nominados/HECTOR CASTILLO.jpg",
    //   title: "Hector Castillo",
    //   description: "Premio Especial"
    // },
    // {
    //   category: "cat-2",
    //   image: "assets/images/nominados/HECTOR CASTILLO EL TIO.jpg",
    //   title: "Hector Castillo (EL TIO)",
    //   description: "Premio Especial"
    // },
    // {
    //   category: "cat-2",
    //   image: "assets/images/nominados/FRANKLIN  NUNEZ.jpg",
    //   title: "Franklin Núñez",
    //   description: "Premio Especial"
    // },
    // {
    //   category: "cat-2",
    //   image: "assets/images/nominados/Bonaerge Linares.jpg",
    //   title: "Bonaerge Linares",
    //   description: "Premio Especial"
    // },
    // {
    //   category: "cat-2",
    //   image: "assets/images/nominados/ROSALIN PEREZ.jpg",
    //   title: "Rosalin Perez",
    //   description: "Premio Especial"
    // },
    // {
    //   category: "cat-2",
    //   image: "assets/images/nominados/ARABELLY CRUZ.jpg",
    //   title: "Arabelly Cruz",
    //   description: "Premio Especial"
    // },
    // {
    //   category: "cat-2",
    //   image: "assets/images/nominados/ALEXIS BARRERA.jpg",
    //   title: "Alexis Barrera",
    //   description: "Premio Especial"
    // },
    // {
    //   category: "cat-2",
    //   image: "assets/images/nominados/YANFOURD.jpg",
    //   title: "Yandourd",
    //   description: "Premio Especial"
    // },
    // {
    //   category: "cat-2",
    //   image: "assets/images/nominados/PEDRO SERRANO.jpg",
    //   title: "Pedro Serrano",
    //   description: "Premio Especial"
    // },
    // {
    //   category: "cat-2",
    //   image: "assets/images/nominados/FRANK AJENO.jpg",
    //   title: "Frank Ajeno",
    //   description: "Premio Especial"
    // },


// Fin Premio Especial

// Locutor del ano
// {
//   category: "cat-3",
//   image: "assets/images/nominados/BONNY BAEZ.jpg",
//   title: "Bonny Baez",
//   description: "Locutor del Año"
// },
// {
//   category: "cat-3",
//   image: "assets/images/nominados/DIONI NOVA.jpg",
//   title: "Dionicio Medrano (Dioni Nova)",
//   description: "Locutor del Año"
// },
// {
//   category: "cat-3",
//   image: "assets/images/nominados/EL CONDO.jpg",
//   title: "El Condo",
//   description: "Locutor del Año"
// },
// {
//   category: "cat-3",
//   image: "assets/images/nominados/EL GENERAL JORGE.jpg",
//   title: "El General Jorge",
//   description: "Locutor del Año"
// },
// {
//   category: "cat-3",
//   image: "assets/images/nominados/RAFAEL VIDAL.jpg",
//   title: "Rafael Vidal",
//   description: "Locutor del Año"
// },
// {
//   category: "cat-3",
//   image: "assets/images/nominados/SANDY SANDY.jpg",
//   title: "Sandy Sandy",
//   description: "Locutor del Año"
// },
// {
//   category: "cat-3",
//   image: "assets/images/nominados/SANDY ALMEYDA.jpg",
//   title: "Sandy Almeyda",
//   description: "Locutor del Año"
// },


// Fin Locutor del ano


//  Locutora del ano

    // {
    //   category: "cat-4",
    //   image: "assets/images/nominados/ARACELIS MARIZAN -.jpg",
    //   title: "Aracelis Merizan",
    //   description: "Locutora del Año"
    // },
    // {
    //   category: "cat-4",
    //   image: "assets/images/nominados/ARLENY JAQUEZ.jpg",
    //   title: "Arleny Jaquez",
    //   description: "Locutora del Año"
    // },
    // {
    //   category: "cat-4",
    //   image: "assets/images/nominados/CRISMY PEREZ.jpg",
    //   title: "Crismy Perez",
    //   description: "Locutora del Año"
    // },
    // {
    //   category: "cat-4",
    //   image: "assets/images/nominados/Ynvelka Espino.jpg",
    //   title: "Ynvelka Espino",
    //   description: "Locutora del Año"
    // },
    // {
    //   category: "cat-4",
    //   image: "assets/images/nominados/MIWANNY RUIZ  SOLANO.jpg",
    //   title: "Miwanny Ruiz Solano",
    //   description: "Locutora del Año"
    // },

    // {
    //   category: "cat-4",
    //   image: "assets/images/nominados/ZAMIRA ESPINOSA.jpg",
    //   title: "Zamira Espinosa",
    //   description: "Locutora del Año"
    // },
    // {
    //   category: "cat-4",
    //   image: "assets/images/nominados/Lilivet Santana.jpg",
    //   title: "lilivet santana",
    //   description: "Locutora del Año"
    // },







  ]
}
