<div class="section-full {{data.bgcolor}}">
    <div class="container">
        <div class="section-content">
        
            <!-- TESTIMONIAL 4 START ON BACKGROUND -->   
            <div class="section-content">
                 <div class="section-content p-tb30 owl-btn-vertical-center">
                    <div class="owl-carousel home-client-carousel-2">
                    
                        <div class="item" *ngFor="let object of data.clients">
                            <div class="ow-client-logo">
                                <div class="client-logo client-logo-media">
                                <a routerLink="/about/about1"><img src="{{object}}" alt=""></a></div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>