<div class="section-content">
    <!-- TITLE START -->
    <div class="text-left">
        <h2 class="wt-title">Oil & Gas Engineering</h2>
    </div>
    <!-- TITLE END -->                                
   <div class="wt-box services-etc m-b30">
       <p>Engineering is concerned with the development, improvement, and implementation of inte grated systems of people, money, knowledge, information, equipment, energy, materials, analysis and synthesis, as well as the mathematical, physical and social sciences together with the principles and methods of engineering design to specify, predict, and evaluate the results to be obtained from such systems or processes.</p>                                   
           <div class="wt-media m-b30">
               <img src="assets/images/services/large/pic1.jpg" alt=""> 
        </div>
        <div class="wt-info">
            <p>Concerned with the development, improvement, and implementation of integrated system of people, money, knowledge, information, equipment, energy, materials, analysis and syn thesis, as well as the mathematical, physical and social sciences together with the principle and methods of engineering design to specify, predict, and evaluate the results to be obtained from such systems or processes.</p>
            
            <blockquote class="m-a0 bg-gray p-a30">
                <i class="fa fa-quote-left font-20"></i>
                <p>Raising a heavy fur muff that covered the whole of her lower arm towards the viewer gregor then turned to look out
                </p>
                <div class="p-t15 text-uppercase">
                    <strong>William Jomurray</strong>
                    <span>Envato Author</span>
                </div>
              
            </blockquote>                                            
        </div>
    </div>
   <div class="service-provide">
           <h2>System Benefits</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p>
        <div class="row">
        
            <div class="col-md-6">
                <ul class="list-angle-right">
                    <li>Pitifully thin compared with the size of the rest of him.</li>
                    <li>Raising a heavy muff that covered the whole. muff that covered the whole of her lower arm consectetur.</li>
                    <li>Muff that covered the whole of her lower arm consectetur adipisicing elit</li>
                </ul>
             </div> 
                                                       
            <div class="col-md-6">
                <div class="wt-media m-b30">
                    <img src="assets/images/gallery/pic3.jpg" alt=""> 
                </div>                                            
            </div>

        </div>
   </div>
   
    
   <div>
           <h2>Research</h2>
        <div class="wt-accordion acc-bg-gray" id="accordion5">
            <div class="panel wt-panel">
                <div class="acod-head acc-actives">
                     <h4 class="acod-title">
                        <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5" >
                           What problems we face?<span class="indicator"><i class="fa fa-plus"></i></span>
                        </a>
                     </h4>
                </div>
                <div id="collapseOne5" class="acod-body collapse show bg-gray">
                    <div class="acod-content p-a15">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, injected humour, or randomised words which don't look even slightly believablThere are many variations of passages.</div>
                </div>
            </div>
            <div class="panel wt-panel">
                <div class="acod-head">
                     <h4 class="acod-title">
                        <a data-toggle="collapse" href="#collapseTwo5" class="collapsed" data-parent="#accordion5" >
                       Was our solution best?<span class="indicator"><i class="fa fa-plus"></i></span>
                        </a>
                     </h4>
                </div>
                <div id="collapseTwo5" class="acod-body collapse bg-gray">
                    <div class="acod-content p-a15">Graphic design lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took.</div>
                </div>
            </div>
            <div class="panel wt-panel">
                <div class="acod-head">
                    <h4 class="acod-title">
                    <a data-toggle="collapse"  href="#collapseThree5" class="collapsed"  data-parent="#accordion5">
                    Was our client satisfy?<span class="indicator"><i class="fa fa-plus"></i></span>
                    </a>
                 </h4>
                </div>
                <div id="collapseThree5" class="acod-body collapse bg-gray">
                    <div class="acod-content p-a15">Developement lorem Ipsum is simply dummy text of the printing and type has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make.</div>
                </div>
            </div>
        </div>                                   
   </div> 
  
</div>