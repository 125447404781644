<div class="page-wraper">

    <!-- HEADER START -->
    <!-- <app-section-header2></app-section-header2> -->
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">

        <!-- INNER PAGE BANNER -->
        <app-section-inner-banner [data]="banner"></app-section-inner-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- OUR BLOG START -->
        <div class="section-full  p-t80 p-b50 bg-white">
            <div class="container">

                <!-- BLOG SECTION START -->
                <div class="section-content">
                    <div class="row d-flex justify-content-center">

                        <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 m-b30">
                           <!-- BLOG START -->
                            <div class="blog-post date-style-2 blog-lg">
                                <!-- <div class="wt-post-media wt-img-effect zoom-slow">
                                    <a><img src="assets/images/blog/default/thum1.jpg" alt=""></a>
                                </div> -->


                          <!-- Dominit RC�HD� video player version YR18- Copyright Dominican Internet Group� -->
                              <iframe
                              width="800px" height="450px" frameBorder="0" allowfullscreen allow-same-origin allow-scripts
                              src="https://dominicanplayers.com/video-player/1707/0">
                            </iframe>
                       <!-- Dominit RC�HD� video player version YR18- Copyright Dominican Internet Group� -->

                            </div>



                            <!-- BLOG END -->

                        </div>

                        <!-- SIDE BAR START -->

                        <!-- SIDE BAR END -->


                    </div>

                </div>

            </div>

         </div>
        <!-- OUR BLOG END -->

    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <!-- <app-section-footer1></app-section-footer1> -->
    <!-- FOOTER END -->

</div>

<app-loader></app-loader>
