import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-blog-post',
  templateUrl: './page-blog-post.component.html',
  styleUrls: ['./page-blog-post.component.css']
})
export class PageBlogPostComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner = {
    background: "assets/images/banner/3.png",
    title: "En Vivo",
    crumb: "en vivo"
  }


}
