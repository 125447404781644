<footer class="site-footer footer-large footer-dark text-white footer-style3" >


    <!-- FOOTER BLOCKES START -->
    <div class="footer-top">
        <div class="container">
          <div class="row">

                <div class="col-lg-3 col-md-6">
                    <div class="widget widget_about">
                        <div class="logo-footer clearfix">
                            <a routerLink="/index"><img id="footerLogo3" src="assets/images/logo.png" alt="" ></a>
                        </div>
                        <p>industroz Industry has very good strength in innovative technology and tools with over 35 years of experience.</p>
                     </div>
                    <div class="widget recent-posts-entry">
                       <ul class="widget_address">
                            <li><i class="fa fa-map-marker"></i>San Francisco City Hall, San Francisco, CA</li>
                            <li><i class="fa fa-envelope"></i>contact123@gmail.com</li>
                            <li> <i class="fa fa-phone"></i>(654) 321-7654 </li>
                        </ul>
                    </div>

                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="widget widget_services">
                        <h3 class="widget-title">Projects</h3>
                        <ul>
                            <li><a routerLink="/projects/project-detail">Regulatory Compliance</a></li>
                            <li><a routerLink="/projects/project-detail">Office Partition Walls</a></li>
                            <li><a routerLink="/projects/project-detail">Highway Energy Station</a></li>
                            <li><a routerLink="/projects/project-detail">Villy Manufacturing</a></li>
                            <li><a routerLink="/projects/project-detail">Compliance Seminar</a></li>
                            <li><a routerLink="/projects/project-detail">Role of the Importer</a></li>
                            <li><a routerLink="/projects/project-detail">Colour Sensing</a></li>
                            <li><a routerLink="/projects/project-detail">Fire Extinguishing</a></li>
                        </ul>

                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="widget widget_services">
                        <h3 class="widget-title">Our Services</h3>
                        <ul>
                            <li><a routerLink="/services/s-oilgas">Oil & Gas Factory</a></li>
                            <li><a routerLink="/services/s-chemical">Chemical Research</a></li>
                            <li><a routerLink="/services/s-mechanical">Mechanical</a></li>
                            <li><a routerLink="/services/s-civil">Manufacturing</a></li>
                            <li><a routerLink="/services/s-automotive">Auto Engineering  </a></li>
                            <li><a routerLink="/services/s-mechanical">Metal Industry </a></li>
                            <li><a routerLink="/services/s-civil">Agricultural Processing</a></li>
                            <li><a routerLink="/services/s-automotive">Material Engineering </a></li>

                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="widget widget_newsletter">
                        <h3 class="widget-title">Newsletter</h3>
                        <p>Subscribe to our newsletter to receive latest news on our services.</p>
                        <div class="newsletter-input">
                            <input id="email" type="text" class="form-control" name="email" placeholder="Enter your email">
                            <button type="submit" class="nl-search-btn site-bg-primary title-style-2">Sign Up</button>
                        </div>
                        <p>We don’t do mail to spam & your mail id is confidential.</p>
                   </div>
                </div>

            </div>
        </div>
    </div>
    <!-- FOOTER COPYRIGHT -->

    <div class="footer-bottom">
      <div class="container">
        <div class="wt-footer-bot-left d-flex justify-content-between">
            <span class="copyrights-text">Copyright © 2021 <span class="site-text-primary">Thewebmax</span></span>
            <ul class="copyrights-nav">
                <li><a routerLink="/about/about1">Terms  &amp; Condition</a></li>
                <li><a routerLink="/about/about2">Privacy Policy</a></li>
                <li><a routerLink="/other/contact1">Contact Us</a></li>
            </ul>
        </div>
      </div>
    </div>


</footer>
