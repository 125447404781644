<div class="page-wraper">


    <!-- CONTENT START -->
    <div class="page-content">

        <!-- INNER PAGE BANNER -->
        <app-section-inner-banner [data]="banner"></app-section-inner-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- GALLERY SECTION START -->
        <div class="section-full p-t80 p-b50 bg-white">
            <div class="container">
                <!-- PAGINATION START -->
                <div class="filter-wrap work-grid p-b30 text-center">
                    <ul class="masonry-filter link-style ">
                            <li class="active"><a data-filter="*" href="javascript:void(0);">Todos</a></li>
                            <li><a data-filter=".cat-1" href="javascript:void(0);">Premio a la Trayectoria</a></li>
                            <li><a data-filter=".cat-2" href="javascript:void(0);">Premio Especial</a></li>
                            <li><a data-filter=".cat-3" href="javascript:void(0);">Locutor del a&ntilde;o</a></li>
                            <li><a data-filter=".cat-4" href="javascript:void(0);">Locutora del a&ntilde;o</a></li>
                            <li><a data-filter=".cat-5" href="javascript:void(0);">Musica Cristiana del a&ntilde;o</a></li>
                            <li><a data-filter=".cat-6" href="javascript:void(0);">Periodista del a&ntilde;o</a></li>
                            <li><a data-filter=".cat-7" href="javascript:void(0);">Bachatero del a&ntilde;o</a></li>
                            <li><a data-filter=".cat-8" href="javascript:void(0);">Artista Femenina del a&ntilde;o</a></li>
                            <li><a data-filter=".cat-9" href="javascript:void(0);">Artista Plastico del a&ntilde;o</a></li>
                            <li><a data-filter=".cat-10" href="javascript:void(0);">Actor  / Actriz del a&ntilde;o</a></li>
                            <li><a data-filter=".cat-11" href="javascript:void(0);">Actor / Actriz de Teatro del a&ntilde;o</a></li>
                            <li><a data-filter=".cat-12" href="javascript:void(0);">Medio Digital del a&ntilde;o</a></li>
                            <li><a data-filter=".cat-13" href="javascript:void(0);">Cronista Deportivo del a&ntilde;o</a></li>
                            <li><a data-filter=".cat-14" href="javascript:void(0);">Baladista del a&ntilde;o</a></li>
                            <li><a data-filter=".cat-15" href="javascript:void(0);">Comentarista de Noticias del a&ntilde;o</a></li>
                            <li><a data-filter=".cat-16" href="javascript:void(0);">Presentador del a&ntilde;o</a></li>
                            <li><a data-filter=".cat-17" href="javascript:void(0);">Presentadora del a&ntilde;o</a></li>
                            <li><a data-filter=".cat-18" href="javascript:void(0);">Merenguero Urbano del a&ntilde;o</a></li>
                            <li><a data-filter=".cat-19" href="javascript:void(0);">Productor Musical a&ntilde;o</a></li>
                            <li><a data-filter=".cat-20" href="javascript:void(0);">Empresario del a&ntilde;o</a></li>
                            <li><a data-filter=".cat-21" href="javascript:void(0);">Merenguero del a&ntilde;o</a></li>
                            <li><a data-filter=".cat-22" href="javascript:void(0);">Salsero del a&ntilde;o</a></li>
                            <li><a data-filter=".cat-23" href="javascript:void(0);">Tipico del a&ntilde;o</a></li>
                            <li><a data-filter=".cat-24" href="javascript:void(0);">Prog. Fin de Semana del a&ntilde;o</a></li>
                            <li><a data-filter=".cat-25" href="javascript:void(0);">Camarografo del a&ntilde;o</a></li>
                            <li><a data-filter=".cat-26" href="javascript:void(0);">Comediante del a&ntilde;o</a></li>
                            <li><a data-filter=".cat-27" href="javascript:void(0);">Urbano del a&ntilde;o</a></li>
                            <li><a data-filter=".cat-28" href="javascript:void(0);">Productor TV del a&ntilde;o</a></li>
                            <li><a data-filter=".cat-29" href="javascript:void(0);">Prog. Diario de Variedades del a&ntilde;o</a></li>
                            <!-- <li><a data-filter=".cat-30" href="javascript:void(0);">Prog. Diario de Variedades del a&ntilde;o</a></li> -->

                    </ul>
                </div>
                <!-- PAGINATION END -->

                <!-- GALLERY CONTENT START -->
                <div class="masonry-wrap mfp-gallery  row clearfix d-flex justify-content-center flex-wrap">
                    <!-- COLUMNS 1 -->
                    <div *ngFor="let object of projects" class="masonry-item {{object.category}} col-lg-4 col-md-6 col-sm-6 m-b30">

                        <div class="project-img-effect-1">
                            <img src="{{object.image}}" alt="" />
                            <div class="wt-info">
                                <h3 class="wt-tilte m-b10 m-t0">{{object.title}}</h3>
                                <p>{{object.description}}</p>
                                <!-- <a routerLink="/projects/project-detail" class="site-button-link">Read More</a> -->
                            </div>

                            <a href="{{object.image}}" class="mfp-link"><i class="fa fa-search-plus"></i></a>
                        </div>

                    </div>

                 </div>
                <!-- GALLERY CONTENT END -->
            </div>
        </div>
        <!-- GALLERY SECTION END -->

    </div>
    <!-- CONTENT END -->



</div>

<app-loader></app-loader>
